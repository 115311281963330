import { ToolkitIconId } from 'asu-sim-toolkit';

export const iconDefinitions = {
  unlink: {
    viewBox: '0 0 26 28',
    content: `
      <path fill="currentColor" d="M6.859 19.859l-4 4c-0.109 0.094-0.234 0.141-0.359 0.141s-0.25-0.047-0.359-0.141c-0.187-0.203-0.187-0.516 0-0.719l4-4c0.203-0.187 0.516-0.187 0.719 0 0.187 0.203 0.187 0.516 0 0.719zM9.5 20.5v5c0 0.281-0.219 0.5-0.5 0.5s-0.5-0.219-0.5-0.5v-5c0-0.281 0.219-0.5 0.5-0.5s0.5 0.219 0.5 0.5zM6 17c0 0.281-0.219 0.5-0.5 0.5h-5c-0.281 0-0.5-0.219-0.5-0.5s0.219-0.5 0.5-0.5h5c0.281 0 0.5 0.219 0.5 0.5zM25.75 19c0 1.203-0.469 2.328-1.328 3.172l-2.297 2.281c-0.844 0.844-1.969 1.297-3.172 1.297s-2.344-0.469-3.187-1.328l-5.219-5.234c-0.266-0.266-0.469-0.562-0.656-0.875l3.734-0.281 4.266 4.281c0.562 0.562 1.563 0.578 2.125 0.016l2.297-2.281c0.281-0.281 0.438-0.656 0.438-1.047 0-0.406-0.156-0.781-0.438-1.062l-4.281-4.297 0.281-3.734c0.313 0.187 0.609 0.391 0.875 0.656l5.25 5.25c0.844 0.859 1.313 1.984 1.313 3.187zM16.109 7.688l-3.734 0.281-4.266-4.281c-0.281-0.281-0.656-0.438-1.062-0.438s-0.781 0.156-1.062 0.422l-2.297 2.281c-0.281 0.281-0.438 0.656-0.438 1.047 0 0.406 0.156 0.781 0.438 1.062l4.281 4.281-0.281 3.75c-0.313-0.187-0.609-0.391-0.875-0.656l-5.25-5.25c-0.844-0.859-1.313-1.984-1.313-3.187s0.469-2.328 1.328-3.172l2.297-2.281c0.844-0.844 1.969-1.297 3.172-1.297s2.344 0.469 3.187 1.328l5.219 5.234c0.266 0.266 0.469 0.562 0.656 0.875zM26 9c0 0.281-0.219 0.5-0.5 0.5h-5c-0.281 0-0.5-0.219-0.5-0.5s0.219-0.5 0.5-0.5h5c0.281 0 0.5 0.219 0.5 0.5zM17.5 0.5v5c0 0.281-0.219 0.5-0.5 0.5s-0.5-0.219-0.5-0.5v-5c0-0.281 0.219-0.5 0.5-0.5s0.5 0.219 0.5 0.5zM23.859 2.859l-4 4c-0.109 0.094-0.234 0.141-0.359 0.141s-0.25-0.047-0.359-0.141c-0.187-0.203-0.187-0.516 0-0.719l4-4c0.203-0.187 0.516-0.187 0.719 0 0.187 0.203 0.187 0.516 0 0.719z" />
    `,
  },
  'chevron-left': {
    viewBox: '0 0 21 28',
    content: `
      <path fill="currentColor" d="M18.297 4.703l-8.297 8.297 8.297 8.297c0.391 0.391 0.391 1.016 0 1.406l-2.594 2.594c-0.391 0.391-1.016 0.391-1.406 0l-11.594-11.594c-0.391-0.391-0.391-1.016 0-1.406l11.594-11.594c0.391-0.391 1.016-0.391 1.406 0l2.594 2.594c0.391 0.391 0.391 1.016 0 1.406z" />
    `,
  },
  'chevron-right': {
    viewBox: '0 0 19 28',
    content: `
      <path fill="currentColor" d="M17.297 13.703l-11.594 11.594c-0.391 0.391-1.016 0.391-1.406 0l-2.594-2.594c-0.391-0.391-0.391-1.016 0-1.406l8.297-8.297-8.297-8.297c-0.391-0.391-0.391-1.016 0-1.406l2.594-2.594c0.391-0.391 1.016-0.391 1.406 0l11.594 11.594c0.391 0.391 0.391 1.016 0 1.406z" />
    `,
  },
  drag: {
    viewBox: '0 0 6 28',
    content: `
      <path fill="currentColor" d="M6 19.5v3c0 0.828-0.672 1.5-1.5 1.5h-3c-0.828 0-1.5-0.672-1.5-1.5v-3c0-0.828 0.672-1.5 1.5-1.5h3c0.828 0 1.5 0.672 1.5 1.5zM6 11.5v3c0 0.828-0.672 1.5-1.5 1.5h-3c-0.828 0-1.5-0.672-1.5-1.5v-3c0-0.828 0.672-1.5 1.5-1.5h3c0.828 0 1.5 0.672 1.5 1.5zM6 3.5v3c0 0.828-0.672 1.5-1.5 1.5h-3c-0.828 0-1.5-0.672-1.5-1.5v-3c0-0.828 0.672-1.5 1.5-1.5h3c0.828 0 1.5 0.672 1.5 1.5z" />
    `,
  },
  draft: {
    viewBox: '0 0 32 32',
    content: `
      <path fill="currentColor" d="M28.681 7.159c-0.694-0.947-1.662-2.053-2.724-3.116s-2.169-2.030-3.116-2.724c-1.612-1.182-2.393-1.319-2.841-1.319h-15.5c-1.378 0-2.5 1.121-2.5 2.5v27c0 1.378 1.122 2.5 2.5 2.5h23c1.378 0 2.5-1.122 2.5-2.5v-19.5c0-0.448-0.137-1.23-1.319-2.841zM24.543 5.457c0.959 0.959 1.712 1.825 2.268 2.543h-4.811v-4.811c0.718 0.556 1.584 1.309 2.543 2.268zM28 29.5c0 0.271-0.229 0.5-0.5 0.5h-23c-0.271 0-0.5-0.229-0.5-0.5v-27c0-0.271 0.229-0.5 0.5-0.5 0 0 15.499-0 15.5 0v7c0 0.552 0.448 1 1 1h7v19.5z" />
    `,
  },
  copy: {
    viewBox: '0 0 32 32',
    content: `
      <path fill="currentColor" d="M20 8v-8h-14l-6 6v18h12v8h20v-24h-12zM6 2.828v3.172h-3.172l3.172-3.172zM2 22v-14h6v-6h10v6l-6 6v8h-10zM18 10.828v3.172h-3.172l3.172-3.172zM30 30h-16v-14h6v-6h10v20z" />
    `,
  },
  eye: {
    viewBox: '0 0 32 32',
    content: `
      <path fill="currentColor" d="M16 6c-6.979 0-13.028 4.064-16 10 2.972 5.936 9.021 10 16 10s13.027-4.064 16-10c-2.972-5.936-9.021-10-16-10zM23.889 11.303c1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303s-5.527-0.796-7.889-2.303c-1.88-1.199-3.473-2.805-4.67-4.697 1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 4.418 3.582 8 8 8s8-3.582 8-8c0-0.962-0.17-1.883-0.482-2.737 0.124 0.074 0.248 0.15 0.371 0.228v0zM16 13c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z" />
    `,
  },
  'eye-blocked': {
    viewBox: '0 0 32 32',
    content: `
      <g fill="currentColor">
        <path d="M29.561 0.439c-0.586-0.586-1.535-0.586-2.121 0l-6.318 6.318c-1.623-0.492-3.342-0.757-5.122-0.757-6.979 0-13.028 4.064-16 10 1.285 2.566 3.145 4.782 5.407 6.472l-4.968 4.968c-0.586 0.586-0.586 1.535 0 2.121 0.293 0.293 0.677 0.439 1.061 0.439s0.768-0.146 1.061-0.439l27-27c0.586-0.586 0.586-1.536 0-2.121zM13 10c1.32 0 2.44 0.853 2.841 2.037l-3.804 3.804c-1.184-0.401-2.037-1.521-2.037-2.841 0-1.657 1.343-3 3-3zM3.441 16c1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 1.715 0.54 3.304 1.459 4.607l-1.904 1.904c-1.639-1.151-3.038-2.621-4.114-4.323z" />
        <path d="M24 13.813c0-0.849-0.133-1.667-0.378-2.434l-10.056 10.056c0.768 0.245 1.586 0.378 2.435 0.378 4.418 0 8-3.582 8-8z" />
        <path d="M25.938 9.062l-2.168 2.168c0.040 0.025 0.079 0.049 0.118 0.074 1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303-1.208 0-2.403-0.149-3.561-0.439l-2.403 2.403c1.866 0.671 3.873 1.036 5.964 1.036 6.978 0 13.027-4.064 16-10-1.407-2.81-3.504-5.2-6.062-6.938z" />
      </g>
    `,
  },
  pencil: {
    viewBox: '0 0 24 28',
    content: `
       <path fill="currentColor" d="M5.672 24l1.422-1.422-3.672-3.672-1.422 1.422v1.672h2v2h1.672zM13.844 9.5c0-0.203-0.141-0.344-0.344-0.344-0.094 0-0.187 0.031-0.266 0.109l-8.469 8.469c-0.078 0.078-0.109 0.172-0.109 0.266 0 0.203 0.141 0.344 0.344 0.344 0.094 0 0.187-0.031 0.266-0.109l8.469-8.469c0.078-0.078 0.109-0.172 0.109-0.266zM13 6.5l6.5 6.5-13 13h-6.5v-6.5zM23.672 8c0 0.531-0.219 1.047-0.578 1.406l-2.594 2.594-6.5-6.5 2.594-2.578c0.359-0.375 0.875-0.594 1.406-0.594s1.047 0.219 1.422 0.594l3.672 3.656c0.359 0.375 0.578 0.891 0.578 1.422z" />
    `,
  },
  note: {
    viewBox: '0 0 24 28',
    content: `
       <path fill="currentColor" d="M16 19.5v6.5h-14.5c-0.828 0-1.5-0.672-1.5-1.5v-21c0-0.828 0.672-1.5 1.5-1.5h21c0.828 0 1.5 0.672 1.5 1.5v14.5h-6.5c-0.828 0-1.5 0.672-1.5 1.5zM18 20h5.953c-0.141 0.75-0.547 1.594-1.016 2.063l-2.875 2.875c-0.469 0.469-1.313 0.875-2.063 1.016v-5.953z" />
    `,
  },
  attach: {
    viewBox: '0 0 32 32',
    content: `
     <path fill="currentColor" d="M20.807 10.22l-2.030-2.029-10.15 10.148c-1.682 1.681-1.682 4.408 0 6.089s4.408 1.681 6.090 0l12.18-12.178c2.804-2.802 2.804-7.346 0-10.148-2.802-2.803-7.347-2.803-10.149 0l-12.788 12.787c-0.009 0.009-0.019 0.018-0.027 0.026-3.909 3.909-3.909 10.245 0 14.153 3.908 3.908 10.246 3.908 14.156 0 0.009-0.009 0.016-0.018 0.026-0.027l0.001 0.001 8.729-8.728-2.031-2.029-8.729 8.727c-0.009 0.008-0.018 0.018-0.026 0.026-2.784 2.783-7.312 2.783-10.096 0-2.783-2.783-2.783-7.31 0-10.093 0.010-0.009 0.019-0.018 0.028-0.026l-0.001-0.002 12.79-12.786c1.678-1.679 4.411-1.679 6.090 0s1.678 4.411 0 6.089l-12.18 12.178c-0.56 0.56-1.47 0.56-2.030 0-0.559-0.559-0.559-1.47 0-2.029l10.15-10.149z" />
    `,
  },
  download: {
    viewBox: '0 0 32 32',
    content: `
     <path fill="currentColor" d="M27.844 11.252c-0.101-4.022-3.389-7.252-7.433-7.252-2.369 0-4.477 1.109-5.839 2.835-0.764-0.987-1.959-1.624-3.303-1.624-2.307 0-4.176 1.871-4.176 4.179 0 0.201 0.015 0.399 0.043 0.592-0.351-0.063-0.711-0.098-1.080-0.098-3.344-0-6.054 2.712-6.054 6.058s2.71 6.058 6.054 6.058h2.868l7.078 7.328 7.078-7.328 3.484-0c3.004-0.006 5.438-2.444 5.438-5.451 0-2.565-1.771-4.716-4.156-5.296zM16 26l-6-6h4v-6h4v6h4l-6 6z" />
    `,
  },
};

export type IconId = keyof typeof iconDefinitions | ToolkitIconId;
