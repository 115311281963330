import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';
import { LoadingScreen } from '../LoadingScreen/LoadingScreen';
import { Header } from '../../components/Header/Header';
import { getFullscreenAction } from '../../components/Header/actions';
import { InstructorReviewPage } from './InstructorReviewPage';
import { RequestStatus } from '../../stores/domain';
import classNames from 'classnames';
import {
  IconButton,
  IconButtonPrimaryClasses,
} from '../../components/IconButton/IconButton';
import { OverviewPage } from '../StudentView/OverviewPage';

type Page = 'draft' | 'reviews';

export const GradingView = observer(() => {
  const {
    fullscreenStore,
    assignmentConfigStore: {
      savedConfig: { title },
    },
    gradingStore: {
      requestStatus,
      fetchGradingData,
      fetchReviewGivenDraftContent,
      instructorReview,
      reviewsGiven,
    },
  } = useStores();
  const [page, setPage] = useState<Page>('draft');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    fetchGradingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reviewsGiven[index]) {
      fetchReviewGivenDraftContent(reviewsGiven[index].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, page]);

  const hasReviews = reviewsGiven.length > 0;

  const headerTitle =
    page === 'draft'
      ? title
      : `${title} - studentx review (${index + 1}/${reviewsGiven.length})`;

  const renderPage = () => {
    if (requestStatus === RequestStatus.Loading || !instructorReview) {
      return <LoadingScreen label="The data is loading. Please wait." />;
    }
    if (page === 'draft') {
      return <InstructorReviewPage review={instructorReview} />;
    }

    const review = reviewsGiven[index];

    return (
      <OverviewPage
        title="Student reviews"
        review={review}
        content={review.content}
        filePath={review.filePath}
      />
    );
  };

  const tabButtonClasses =
    'focus:outline-none focus:ring-2 focus:ring-keyboardCue flex items-center justify-center px-[10px] py-[6px] font-semibold text-blue border-white rounded-t-md rounded-b-none disabled:cursor-not-allowed';

  return (
    <div className="h-full w-full bg-white">
      <Header
        title={headerTitle}
        action={getFullscreenAction(fullscreenStore)}
        leftActions={
          <div className="flex gap-sm pl-sm pt-sm">
            <button
              onClick={() => setPage('draft')}
              className={classNames(
                tabButtonClasses,
                page !== 'draft' ? 'bg-gray-500' : 'bg-white'
              )}
            >
              Draft
            </button>
            <button
              onClick={() => setPage('reviews')}
              className={classNames(
                tabButtonClasses,
                page !== 'reviews' ? 'bg-gray-500' : 'bg-white'
              )}
              disabled={!hasReviews}
            >
              Reviews
            </button>
          </div>
        }
      >
        {page === 'reviews' && (
          <>
            <IconButton
              className={classNames(IconButtonPrimaryClasses, 'h-full')}
              iconId="chevron-left"
              disabled={index === 0}
              onClick={() => {
                setIndex(index - 1);
              }}
              label="Previous review"
              id="see-previous-review"
            />
            <IconButton
              className={classNames(IconButtonPrimaryClasses, 'h-full')}
              iconId="chevron-right"
              disabled={
                index === reviewsGiven.length - 1 || reviewsGiven.length === 0
              }
              onClick={() => {
                setIndex(index + 1);
              }}
              label="Next review"
              id="see-next-review"
            />
          </>
        )}
      </Header>
      {renderPage()}
    </div>
  );
});
