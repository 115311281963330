import classNames from 'classnames';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

interface ITableColumn {
  data: ReactNode;
  className?: string;
}

interface ITableProps extends PropsWithChildren {
  className?: string;
  columns: ITableColumn[];
  rows: ITableColumn[][];
}

export const Table: FC<ITableProps> = ({ className, columns, rows }) => (
  <div
    className={classNames(
      'overflow-x-auto p-2 rounded-md bg-gray-200',
      className
    )}
  >
    <table className="min-w-full text-left text-sm">
      <thead className="font-semibold">
        <tr className="border-b border-gray-300">
          {columns.map(({ data, className }, index) => (
            <th
              key={`col-${index}`}
              scope="col"
              className={classNames('whitespace-nowrap px-4 py-2', className)}
            >
              {data}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((columns, rowIndex) => (
          <tr
            key={`row-${rowIndex}`}
            className="border-b border-gray-300 last:border-b-0"
          >
            {columns.map(({ data, className }, colIndex) => (
              <td
                key={`row-${rowIndex}-col-${colIndex}`}
                className={classNames('whitespace-nowrap px-4 py-2', className)}
              >
                {data}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
