import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';
import { Button, buttonPrimaryClasses } from '../../components/Button/Button';
import classNames from 'classnames';
import { Table } from '../../components/Table/Table';
import { RequestStatus } from '../../stores/domain';
import { Spinner } from '../../components/Spinner/Spinner';
import { reviewStatusLabels } from '../../utils/enum-mappers';

export const ReviewSelection = observer(() => {
  const {
    solutionReviewStore: {
      reviews,
      fetchReviews,
      requestStatus,
      setActiveReview,
    },
    solutionDraftStore: { status: draftStatus },
  } = useStores();

  useEffect(() => {
    fetchReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reviewsArray = reviews.map(({ id, status }, index) => [
    {
      data: `${index + 1}`,
    },
    {
      data: reviewStatusLabels[status],
    },
    {
      data:
        status !== 'submitted' ? (
          status === 'not-begun' ? (
            <Button
              className={classNames(buttonPrimaryClasses, 'ml-auto text-xs')}
              onClick={async () => {
                setActiveReview(id);
              }}
            >
              Begin Review
            </Button>
          ) : (
            <Button
              className={classNames(buttonPrimaryClasses, 'ml-auto text-xs')}
              onClick={() => {
                setActiveReview(id);
              }}
            >
              Edit Review
            </Button>
          )
        ) : null,
    },
  ]);

  return (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-semibold">Reviews To Give</div>
      {requestStatus === RequestStatus.Loading ? (
        <Spinner />
      ) : reviews.length === 0 ? (
        <div className="p-2 rounded-md bg-gray-50 text-sm flex flex-col gap-2">
          {draftStatus !== 'submitted' && (
            <div>
              You cannot review peers until you have completed and submitted a
              draft of your own.
            </div>
          )}
          <div className="text-warning">
            If you submitted a draft and do not see the option to review your
            peers, you may need to refresh or wait until more of your peers have
            submitted their drafts.
          </div>
        </div>
      ) : (
        <Table
          columns={[
            { data: '' },
            { data: 'Status' },
            { data: '', className: 'text-right w-full' },
          ]}
          rows={reviewsArray}
        />
      )}
    </div>
  );
});
