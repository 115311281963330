import React, { FC } from 'react';

import { StoreProvider } from './providers/store/store-provider';
import { MainView } from './views/MainView/MainView';
import { IconSheet } from './components/Icon';
import { CAPI } from 'asu-sim-toolkit';
import { ICapiModel } from './capi';
import { RootStore } from './stores';

interface IAppProps {
  capi: CAPI<ICapiModel>;
}

export const App: FC<IAppProps> = ({ capi }) => {
  const rootStore = new RootStore(capi);

  return (
    <StoreProvider store={rootStore}>
      <IconSheet />
      <MainView />
    </StoreProvider>
  );
};
