import { IDraft } from '../stores/domain';

import { IDraftDataSource } from './types';
import {
  IGetDraftApi,
  IGetDraftsApi,
  IPostDraftApi,
  IPutDraftApi,
} from './types.rest';
import { mapToDraft } from './mappers.rest';

export class DraftDataSource implements IDraftDataSource {
  constructor(private readonly apiRoot: string) {}

  async getDrafts({
    assignmentId,
    cohortId,
    userId,
    status,
  }: {
    assignmentId?: IGetDraftsApi['queryParams']['assignmentId'];
    cohortId?: IGetDraftsApi['queryParams']['cohortId'];
    userId?: IGetDraftsApi['queryParams']['studentId'];
    status?: IGetDraftsApi['queryParams']['status'];
  }): Promise<IDraft[]> {
    const url = new URL(`${this.apiRoot}/drafts`);

    if (assignmentId !== undefined) {
      url.searchParams.append('assignmentId', assignmentId);
    }
    if (cohortId !== undefined) {
      url.searchParams.append('cohortId', cohortId);
    }
    if (userId !== undefined) {
      url.searchParams.append('studentId', userId);
    }
    if (status !== undefined) {
      url.searchParams.append('status', status);
    }

    const res = await fetch(url.toString());

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IGetDraftsApi['response'];

    return data.map((item) => mapToDraft(item));
  }

  async getDraft(
    draftId: IGetDraftApi['pathParams']['draftId']
  ): Promise<IDraft> {
    const res = await fetch(`${this.apiRoot}/drafts/${draftId}`);

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IGetDraftApi['response'];

    return mapToDraft(data);
  }

  async createDraft({
    assignmentId,
    cohortId,
    content,
    userId,
    filePath,
  }: IDraft): Promise<IDraft> {
    const payload: IPostDraftApi['payload'] = {
      data: {
        content,
        filePath,
        assignmentId,
        cohortId,
        studentId: userId,
      },
      userId,
    };

    const res = await fetch(`${this.apiRoot}/drafts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IPostDraftApi['response'];

    return mapToDraft(data);
  }

  async updateDraft(
    { userId, status, content, filePath }: IDraft,
    draftId: IPutDraftApi['pathParams']['draftId']
  ): Promise<IDraft> {
    const payload: IPutDraftApi['payload'] = {
      data: {
        content,
        filePath,
        status,
      },
      userId,
    };

    const res = await fetch(`${this.apiRoot}/drafts/${draftId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IPutDraftApi['response'];

    return mapToDraft(data);
  }
}
