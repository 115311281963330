import { NotificationType } from 'asu-sim-toolkit';
import { IGradingStore, IRootStore } from './types';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  IRankScore,
  IInstructorReview,
  IReviewData,
  RequestStatus,
  IReviewGiven,
} from './domain';
import { IReviewDataSource, IDraftDataSource } from '../data-sources/types';

export class GradingStore implements IGradingStore {
  private readonly rootStore: IRootStore;
  private readonly reviewDataSource: IReviewDataSource;
  private readonly draftDataSource: IDraftDataSource;
  instructorReview: IInstructorReview | null;
  reviewsGiven: IReviewGiven[];
  reviewsReceived: IReviewData[];
  requestStatus: RequestStatus;

  constructor(
    rootStore: IRootStore,
    reviewDataSource: IReviewDataSource,
    draftDataSource: IDraftDataSource
  ) {
    this.rootStore = rootStore;
    this.reviewDataSource = reviewDataSource;
    this.draftDataSource = draftDataSource;

    this.instructorReview = null;
    this.reviewsGiven = [];
    this.reviewsReceived = [];
    this.requestStatus = RequestStatus.Idle;

    makeObservable(this, {
      instructorReview: observable,
      reviewsGiven: observable,
      reviewsReceived: observable,
      requestStatus: observable,
      totalScore: computed,

      updateRank: action.bound,
      fetchGradingData: action.bound,
      fetchReviewGivenDraftContent: action.bound,
    });
  }

  updateRank(rank: IRankScore) {
    if (!this.instructorReview) return;

    const rankIndex = this.instructorReview.ranks.findIndex(
      (r) => r.criteriaId === rank.criteriaId
    );

    if (rankIndex === -1) {
      this.instructorReview.ranks.push(rank);
    } else {
      this.instructorReview.ranks[rankIndex] = rank;
    }
  }

  get totalScore() {
    const criteria = this.rootStore.assignmentConfigStore.savedConfig.criteria;
    let score = 0;

    criteria.forEach(
      ({ gradeWeight: criteriaWeight, ranks: criteriaRanks }) => {
        score +=
          criteriaWeight *
          (this.instructorReview?.ranks.find((r) =>
            criteriaRanks.some((cr) => r.rankId === cr.id)
          )?.score || 0);
      }
    );

    return score;
  }

  async fetchGradingData() {
    const { id } = this.rootStore.assignmentConfigStore.savedConfig;
    const { cohortId, userId } = this.rootStore.appStore;

    try {
      this.requestStatus = RequestStatus.Loading;

      // DEBUG: Remove this
      console.log('--- FETCHING DRAFTS/REVIEWS ---');
      console.table({
        userId,
        assignmentId: id,
        cohortId,
      });

      const draftsData = await this.draftDataSource.getDrafts({
        userId,
        assignmentId: id,
        cohortId,
      });

      const draft = draftsData?.[0] || null;

      runInAction(() => {
        this.instructorReview = {
          content: draft.content,
          filePath: draft.filePath,
          ranks: [],
        };
      });

      const reviewsReceivedData = await this.reviewDataSource.getReviews({
        assignmentId: id,
        draftId: draft.id,
        status: 'submitted',
      });

      const reviewsGivenData = await this.reviewDataSource.getReviews({
        userId,
        assignmentId: id,
        status: 'submitted',
      });

      runInAction(() => {
        this.reviewsReceived = reviewsReceivedData;
        this.reviewsGiven = reviewsGivenData.map((review) => ({
          ...review,
          userId,
          assignmentId: id,
          content: '',
        }));
        this.requestStatus = RequestStatus.Success;
      });
    } catch (err) {
      // DEBUG: Remove this
      console.warn((err as Error)?.message || err);
      runInAction(() => {
        this.rootStore.notificationStore.addNotification(
          'Something went wrong while fetching data for grading.',
          { type: NotificationType.error }
        );
        this.requestStatus = RequestStatus.Error;
      });
    }
  }

  async fetchReviewGivenDraftContent(reviewId: string) {
    const reviewGiven = this.reviewsGiven.find((r) => r.id === reviewId);
    if (
      !reviewGiven ||
      Boolean(reviewGiven.content) ||
      Boolean(reviewGiven.filePath)
    ) {
      return;
    }

    try {
      this.requestStatus = RequestStatus.Loading;
      const draftData = await this.draftDataSource.getDraft(
        reviewGiven.draftId
      );

      runInAction(() => {
        this.reviewsGiven = this.reviewsGiven.map((r) => {
          if (r.id === reviewId) {
            return {
              ...r,
              content: draftData.content,
              filePath: draftData.filePath,
            };
          }
          return r;
        });
        this.requestStatus = RequestStatus.Success;
      });
    } catch (err) {
      runInAction(() => {
        this.rootStore.notificationStore.addNotification(
          'Something went wrong while fetching draft content for given review',
          { type: NotificationType.error }
        );
        this.requestStatus = RequestStatus.Error;
      });
    }
  }
}
