import {
  RecapStore,
  ModalStore,
  NotificationStore,
  INotificationStore,
  IModalStore,
  IRecapStore,
  IFullscreenStore,
  FullscreenStore,
  SimContextStore,
  ISimContextStore,
  CAPI,
} from 'asu-sim-toolkit';

import { isDebugMode, REST_API_ROOT } from '../config';
import {
  IAppStore,
  IAssignmentConfigStore,
  IFileUploader,
  IGradingStore,
  IRootStore,
  ISolutionDraftStore,
  ISolutionReviewStore,
  ISolutionStore,
} from './types';
import { ICapiModel } from '../capi';
import { AppStore } from './app-store';
import { versionHistory } from '../version-history';
import { AssignmentConfigStore } from './assignment-config-store';
import { AssignmentDataSource } from '../data-sources/assignment-data-source';
import { SolutionDraftStore } from './solution-draft-store';
import { DraftDataSource } from '../data-sources/draft-data-source';
import { SolutionReviewStore } from './solution-review-store';
import { ReviewDataSource } from '../data-sources/review-data-source';
import { GradingStore } from './grading-store';
import { FileDataSource } from '../data-sources/file-data-source';
import { FileUploader } from './file-uploader';
import { SolutionStore } from './solution-store';

export class RootStore implements IRootStore {
  appStore: IAppStore;
  assignmentConfigStore: IAssignmentConfigStore;
  notificationStore: INotificationStore;
  modalStore: IModalStore;
  recapStore: IRecapStore;
  fullscreenStore: IFullscreenStore;
  simContextStore: ISimContextStore;
  solutionDraftStore: ISolutionDraftStore;
  solutionReviewStore: ISolutionReviewStore;
  solutionStore: ISolutionStore;
  gradingStore: IGradingStore;
  fileUploader: IFileUploader;

  constructor(capi: CAPI<ICapiModel>) {
    if (isDebugMode) {
      (window as any).capi = capi;
      (window as any).store = this;
    }
    const ATTACHMENT_UPLOAD_URL =
      'https://2reh3zg2me.execute-api.us-west-2.amazonaws.com/Stage/files';

    const assignmentDataSource = new AssignmentDataSource(REST_API_ROOT);
    const draftDataSource = new DraftDataSource(REST_API_ROOT);
    const reviewDataSource = new ReviewDataSource(REST_API_ROOT);
    const fileDataSource = new FileDataSource(ATTACHMENT_UPLOAD_URL);

    this.notificationStore = new NotificationStore();
    this.modalStore = new ModalStore();
    this.recapStore = new RecapStore(this.modalStore, versionHistory);
    this.fullscreenStore = new FullscreenStore(capi);
    this.simContextStore = new SimContextStore(capi);
    this.appStore = new AppStore(this, capi);
    this.assignmentConfigStore = new AssignmentConfigStore(
      this,
      capi,
      assignmentDataSource,
      draftDataSource
    );
    this.solutionDraftStore = new SolutionDraftStore(
      this,
      capi,
      draftDataSource,
      reviewDataSource
    );
    this.solutionReviewStore = new SolutionReviewStore(
      this,
      reviewDataSource,
      draftDataSource
    );
    this.solutionStore = new SolutionStore(this, capi);
    this.gradingStore = new GradingStore(
      this,
      reviewDataSource,
      draftDataSource
    );
    this.fileUploader = new FileUploader(this, fileDataSource);
  }
}
