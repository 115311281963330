import React, { CSSProperties, FC } from 'react';
import { isFilePDF } from '../../utils/is-file-pdf';

interface IAttachedFileProps {
  filePath?: string;
  title?: string;
  style?: CSSProperties;
}

export const AttachedFile: FC<IAttachedFileProps> = ({
  filePath,
  title = 'Attached file',
  style,
}) =>
  filePath ? (
    <div
      className="w-full h-full border border-gray-500 rounded-md overflow-hidden mb-6"
      style={style}
    >
      <div className="flex justify-between align-center gap-2 bg-gray-200 p-2 border-b border-gray-500">
        <h5 className="text-lg font-semibold ml-1">{title}</h5>
      </div>
      <embed
        src={filePath}
        width="100%"
        height="800px"
        style={{ height: isFilePDF(filePath) ? '100%' : 'auto' }}
      />
    </div>
  ) : null;
