import { NotificationType } from 'asu-sim-toolkit';
import { IRootStore, ISolutionReview, ISolutionReviewStore } from './types';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  IReview,
  IRankScore,
  RequestStatus,
  ReviewStatus,
  INote,
  ReviewQueryParams,
} from './domain';
import { IDraftDataSource, IReviewDataSource } from '../data-sources/types';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';

export class SolutionReview implements ISolutionReview {
  private readonly rootStore: IRootStore;
  private readonly reviewDataSource: IReviewDataSource;
  private readonly draftDataSource: IDraftDataSource;
  id: string;
  draftId: string;
  ranks: IRankScore[];
  score: number;
  notes: INote[];
  status: ReviewStatus;
  content: string;
  filePath?: string;

  isChanged = false;
  requestStatus = RequestStatus.Idle;

  constructor(
    rootStore: IRootStore,
    reviewDataSource: IReviewDataSource,
    draftDataSource: IDraftDataSource,
    initialConfig: IReview
  ) {
    this.reviewDataSource = reviewDataSource;
    this.draftDataSource = draftDataSource;
    this.rootStore = rootStore;

    this.id = initialConfig.id;
    this.draftId = initialConfig.draftId;
    this.ranks = initialConfig.ranks;
    this.score = initialConfig.score;
    this.notes = initialConfig.notes;
    this.status = initialConfig.status;
    this.content = '';
    this.filePath = undefined;
    this.initGeneralNotes();

    makeObservable(this, {
      requestStatus: observable,
      canSubmit: computed,
      isChanged: observable,
      draftId: observable,
      ranks: observable,
      score: observable,
      notes: observable,
      status: observable,
      content: observable,
      filePath: observable,
      totalScore: computed,

      initGeneralNotes: action,
      updateRank: action.bound,
      updateNote: action.bound,
      addNote: action.bound,
      removeNote: action.bound,
      fetchDraftContent: action.bound,
      save: action.bound,
      submit: action.bound,
      toJS: action.bound,
    });
  }

  initGeneralNotes() {
    const criteria = this.rootStore.assignmentConfigStore.savedConfig.criteria;

    criteria.forEach((c) => {
      if (!this.notes.some((n) => n.criteriaId === c.id && n.length === 0)) {
        this.notes.push({
          id: crypto.randomUUID(),
          criteriaId: c.id,
          content: '',
          startIndex: 0,
          length: 0,
        });
      }
    });
  }

  updateRank(rank: IRankScore) {
    this.isChanged = true;
    const rankIndex = this.ranks.findIndex(
      (r) => r.criteriaId === rank.criteriaId
    );

    if (rankIndex === -1) {
      this.ranks.push(rank);
    } else {
      this.ranks[rankIndex] = rank;
    }

    this.score = this.totalScore;
  }

  updateNote(id: string, content: string) {
    this.isChanged = true;
    const noteIndex = this.notes.findIndex((n) => n.id === id);

    if (noteIndex !== -1) {
      this.notes[noteIndex] = { ...this.notes[noteIndex], content };
    }
  }

  addNote({
    criteriaId,
    startIndex,
    length,
  }: {
    criteriaId: string;
    startIndex: number;
    length: number;
  }) {
    this.isChanged = true;
    this.notes.push({
      id: crypto.randomUUID(),
      criteriaId,
      content: '',
      startIndex: startIndex,
      length: length,
    });
  }

  removeNote(id: string) {
    this.isChanged = true;
    const index = this.notes.findIndex((note) => note.id === id);

    if (index !== -1) {
      this.notes.splice(index, 1);
    }
  }

  async fetchDraftContent() {
    if (Boolean(this.content) || Boolean(this.filePath)) return;

    this.requestStatus = RequestStatus.Loading;
    const data = await this.draftDataSource.getDraft(this.draftId);

    runInAction(() => {
      this.content = data.content;
      this.filePath = data.filePath;
      this.requestStatus = RequestStatus.Idle;
    });
  }

  async save() {
    this.isChanged = false;
    const assignmentConfig = this.rootStore.assignmentConfigStore.savedConfig;
    const userId = this.rootStore.appStore.userId;

    const payload: IReview = {
      assignmentId: assignmentConfig.id,
      userId: userId,
      id: this.id,
      draftId: this.draftId,
      ranks: this.ranks,
      score: this.score,
      notes: this.notes,
      status: 'in-progress',
    };

    try {
      this.requestStatus = RequestStatus.Loading;

      const data = await this.reviewDataSource.updateReview(payload, this.id);

      runInAction(() => {
        this.id = data.id;
        this.status = data.status;

        this.rootStore.notificationStore.addNotification(
          'Your review has been saved.',
          {
            type: NotificationType.success,
          }
        );
        this.requestStatus = RequestStatus.Success;
      });
    } catch (err) {
      runInAction(() => {
        this.rootStore.notificationStore.addNotification(
          'Something went wrong while saving the review.',
          { type: NotificationType.error }
        );
        this.requestStatus = RequestStatus.Error;
      });
    }
  }

  async submit() {
    this.isChanged = false;
    const assignmentConfig = this.rootStore.assignmentConfigStore.savedConfig;
    const userId = this.rootStore.appStore.userId;

    const payload: IReview = {
      assignmentId: assignmentConfig.id,
      userId: userId,
      id: this.id,
      draftId: this.draftId,
      ranks: this.ranks,
      score: this.score,
      notes: this.notes,
      status: 'submitted',
    };

    try {
      const isConfirmed = await this.rootStore.modalStore.modal(
        ConfirmationModal,
        {
          title: 'Are you sure?',
          message:
            'Once submitted, you cannot go back to make additional edits to your review.',
          confirmLabel: 'Submit',
          denyLabel: 'Cancel',
        }
      );

      if (!isConfirmed) return;

      this.requestStatus = RequestStatus.Loading;

      await this.reviewDataSource.updateReview(payload, this.id);

      runInAction(() => {
        this.isChanged = false;
        this.status = 'submitted';

        this.rootStore.notificationStore.addNotification(
          'Your review has been submitted.',
          {
            type: NotificationType.success,
          }
        );
        this.requestStatus = RequestStatus.Success;
      });
    } catch (err) {
      runInAction(() => {
        this.rootStore.notificationStore.addNotification(
          'Something went wrong while submitting your review.',
          { type: NotificationType.error }
        );
        this.requestStatus = RequestStatus.Error;
      });
    }
  }

  get canSubmit() {
    const criteria = this.rootStore.assignmentConfigStore.savedConfig.criteria;

    return this.ranks.length === criteria.length;
  }

  get totalScore() {
    const criteria = this.rootStore.assignmentConfigStore.savedConfig.criteria;
    let score = 0;

    criteria.forEach(
      ({ gradeWeight: criteriaWeight, ranks: criteriaRanks }) => {
        score +=
          criteriaWeight *
          (this.ranks.find((r) =>
            criteriaRanks.some((cr) => r.rankId === cr.id)
          )?.score || 0);
      }
    );

    return score;
  }

  toJS() {
    return {
      id: this.id,
      draftId: this.draftId,
      ranks: this.ranks,
      score: this.score,
      notes: this.notes,
      status: this.status,
    };
  }
}

export class SolutionReviewStore implements ISolutionReviewStore {
  private readonly rootStore: IRootStore;
  private readonly reviewDataSource: IReviewDataSource;
  private readonly draftDataSource: IDraftDataSource;
  reviews: ISolutionReview[];

  requestStatus = RequestStatus.Idle;
  activeReview: ISolutionReview | null;

  constructor(
    rootStore: IRootStore,
    reviewDataSource: IReviewDataSource,
    draftDataSource: IDraftDataSource
  ) {
    this.reviewDataSource = reviewDataSource;
    this.draftDataSource = draftDataSource;
    this.rootStore = rootStore;
    this.reviews = [];
    this.activeReview = null;

    makeObservable(this, {
      requestStatus: observable,
      reviews: observable,
      activeReview: observable,
      areReviewsSubmitted: computed,

      fetchReviews: action.bound,
      setActiveReview: action.bound,
    });
  }

  async fetchReviews(queryParams?: ReviewQueryParams) {
    try {
      const { id } = this.rootStore.assignmentConfigStore.savedConfig;
      const userId = this.rootStore.appStore.userId;

      this.requestStatus = RequestStatus.Loading;

      const data = await this.reviewDataSource.getReviews({
        userId,
        assignmentId: id,
        ...queryParams,
      });

      runInAction(() => {
        this.reviews = data.map(
          (d) =>
            new SolutionReview(
              this.rootStore,
              this.reviewDataSource,
              this.draftDataSource,
              d
            )
        );

        this.requestStatus = RequestStatus.Success;
      });
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.rootStore.notificationStore.addNotification(
          'Something went wrong while fetching your reviews.',
          { type: NotificationType.error }
        );
        this.requestStatus = RequestStatus.Error;
      });
    }
  }

  setActiveReview(id: string | null) {
    if (!id) {
      this.activeReview = null;
      return;
    }
    const activeReview = this.reviews.find((r) => r.id === id) || null;

    activeReview?.fetchDraftContent();

    this.activeReview = activeReview;
  }

  get areReviewsSubmitted() {
    return this.reviews.every((r) => r.status === 'submitted');
  }
}
