import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import {
  IconButton,
  IconButtonPrimaryClasses,
} from '../../components/IconButton/IconButton';
import { useStores } from '../../providers/store/use-stores';
import { Header } from '../../components/Header/Header';
import { getFullscreenAction } from '../../components/Header/actions';
import { Draft } from '../../components/Displayer/Draft';
import { AttachedFile } from '../../components/Displayer/AttachedFile';
import { OverviewPage } from './OverviewPage';

interface IDraftOverviewViewProps {
  goBack(): void;
}

export const DraftOverviewView: FC<IDraftOverviewViewProps> = observer(
  ({ goBack }) => {
    const {
      fullscreenStore,
      assignmentConfigStore: {
        savedConfig: { title },
      },
      solutionDraftStore: { reviews, content, filePath },
    } = useStores();
    const [index, setIndex] = useState(0);

    const hasReviews = reviews.length > 0;

    const headerTitle = !hasReviews
      ? title
      : `${title} - review (${index + 1}/${reviews.length})`;

    return (
      <div className="w-full h-full bg-white flex flex-col">
        <Header
          title={headerTitle}
          action={getFullscreenAction(fullscreenStore)}
          goBack={goBack}
        >
          {hasReviews && (
            <>
              <IconButton
                className={classNames(IconButtonPrimaryClasses, 'h-full')}
                iconId="chevron-left"
                disabled={index === 0}
                onClick={() => {
                  setIndex(index - 1);
                }}
                label="Previous review"
                id="see-previous-review"
              />
              <IconButton
                className={classNames(IconButtonPrimaryClasses, 'h-full')}
                iconId="chevron-right"
                disabled={index === reviews.length - 1 || reviews.length === 0}
                onClick={() => {
                  setIndex(index + 1);
                }}
                label="Next review"
                id="see-next-review"
              />
            </>
          )}
        </Header>
        {!hasReviews ? (
          <>
            <div className="p-6 text-lg font-semibold">
              No reviews received yet.
            </div>
            <div className="px-6 w-full h-full">
              <Draft content={content} title="Your draft" />
              <AttachedFile filePath={filePath} title="Your attached file" />
            </div>
          </>
        ) : (
          <OverviewPage
            review={reviews[index]}
            content={content}
            filePath={filePath}
          />
        )}
      </div>
    );
  }
);
