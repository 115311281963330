import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { TextInput } from '../TextInput/TextInput';
import { NumberInput } from '../NumberInput/NumberInput';
import { IconButton, IconButtonPrimaryClasses } from '../IconButton/IconButton';
import { Textarea } from '../Textarea/Textarea';
import classNames from 'classnames';
import { ICriteriaRank } from '../../stores/types';
import { MIN_RANK_NUMBER } from '../../stores/config';

interface IRubricRankProps {
  criteriaRank: ICriteriaRank;
  ranksLength: number;

  onRemove(id: string): void;
  sortRanks(): void;
}

export const RubricRank: FC<IRubricRankProps> = observer(
  ({ criteriaRank, ranksLength, onRemove, sortRanks }) => {
    const { id, name, description, gradeWeight, updateData } = criteriaRank;

    return (
      <div className="w-full flex flex-col border border-gray-500 bg-gray-50 rounded-md">
        <div className="flex justify-between items-center p-2">
          <div className="flex w-full items-center gap-2">
            <TextInput
              className="w-[120px]"
              value={name}
              onChange={(v) => updateData({ name: v })}
            />
            <NumberInput
              className="w-[50px]"
              value={gradeWeight}
              onChange={(v) => updateData({ gradeWeight: v })}
              onBlur={sortRanks}
            />
          </div>
          <IconButton
            iconId="trash"
            id={`remove-${id}-rank`}
            label={`Remove '${name}' rank`}
            disabledLabel={`The fewest number of ratings is ${MIN_RANK_NUMBER}`}
            className={classNames(IconButtonPrimaryClasses)}
            onClick={() => onRemove(id)}
            disabled={ranksLength <= MIN_RANK_NUMBER}
          />
        </div>
        <div className="w-full min-h-[400px] flex px-2 pb-2">
          <Textarea
            inputClassName="h-full border-gray-500 rounded-md"
            value={description}
            placeholder="Enter grade description here..."
            onChange={(v) => updateData({ description: v })}
          />
        </div>
      </div>
    );
  }
);
