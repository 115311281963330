import { CapiBoundStore, ICAPI, NotificationType } from 'asu-sim-toolkit';
import { ICapiModel } from '../capi';
import { IAssignmentConfigStore, ICriteriaStore, IRootStore } from './types';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {
  IAssignmentConfig,
  IAssignmentCriterion,
  DEFAULT_CRITERION,
} from './domain';
import { CriteriaStore } from './criteria-store';
import { IAssignmentDataSource, IDraftDataSource } from '../data-sources/types';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';

const DEFAULT_CONFIG: IAssignmentConfig = {
  id: '',
  title: '',
  summary: '',
  criteria: [DEFAULT_CRITERION],
};

class AssignmentConfig extends CapiBoundStore<ICapiModel> {
  id: string;
  title: string;
  summary?: string;
  criteria: IAssignmentCriterion[];
  minNumSubmissions?: number;
  numReviewsPerStudent?: number;

  constructor(
    capi: ICAPI<ICapiModel>,
    initialConfig: IAssignmentConfig,
    synchronize: 'twoWay' | 'fromCAPI'
  ) {
    super(capi);

    this.id = initialConfig.id;
    this.title = initialConfig.title;
    this.summary = initialConfig.summary;
    this.criteria = initialConfig.criteria;
    this.minNumSubmissions = initialConfig.minNumSubmissions;
    this.numReviewsPerStudent = initialConfig.numReviewsPerStudent;

    makeObservable(this, {
      id: observable,
      title: observable,
      summary: observable,
      criteria: observable,
      minNumSubmissions: observable,
      numReviewsPerStudent: observable,
    });

    if (synchronize === 'twoWay') {
      this.bindToCapi('title', 'Sim.Configuration.Assignment.Title');
      this.bindToCapi('summary', 'Sim.Configuration.Assignment.Summary');
      this.bindToCapi('id', 'Sim.Configuration.Assignment.Id');
    }

    if (synchronize === 'fromCAPI') {
      this.synchronizeFromCapi('title', 'Sim.Configuration.Assignment.Title');
      this.synchronizeFromCapi(
        'summary',
        'Sim.Configuration.Assignment.Summary'
      );
      this.synchronizeFromCapi('id', 'Sim.Configuration.Assignment.Id');
    }
  }
}

export class AssignmentConfigStore
  extends CapiBoundStore<ICapiModel>
  implements IAssignmentConfigStore
{
  private readonly rootStore: IRootStore;
  private readonly assignmentDataSource: IAssignmentDataSource;
  private readonly draftDataSource: IDraftDataSource;

  currentConfig: AssignmentConfig;
  savedConfig: AssignmentConfig;
  criteriaManager: ICriteriaStore;

  isChanged = false;
  isReady = true;

  constructor(
    rootStore: IRootStore,
    capi: ICAPI<ICapiModel>,
    assignmentDataSource: IAssignmentDataSource,
    draftDataSource: IDraftDataSource
  ) {
    super(capi);

    this.assignmentDataSource = assignmentDataSource;
    this.draftDataSource = draftDataSource;

    this.rootStore = rootStore;
    this.currentConfig = new AssignmentConfig(capi, DEFAULT_CONFIG, 'fromCAPI');
    this.savedConfig = new AssignmentConfig(capi, DEFAULT_CONFIG, 'twoWay');
    this.criteriaManager = new CriteriaStore(DEFAULT_CONFIG.criteria);

    makeObservable(this, {
      isChanged: observable,
      isReady: observable,
      isDataChanged: computed,

      updateData: action.bound,
      save: action.bound,
      discard: action.bound,
      loadAssignmentConfig: action.bound,
    });

    reaction(
      () => this.savedConfig.id,
      (v) => {
        this.loadAssignmentConfig(v);
      },
      { fireImmediately: true }
    );
  }

  updateData(changedFields: Partial<IAssignmentConfig>) {
    this.isChanged = true;

    if (changedFields.title !== undefined) {
      this.currentConfig.title = changedFields.title;
    }

    if (changedFields.summary !== undefined) {
      this.currentConfig.summary = changedFields.summary;
    }

    if (changedFields.minNumSubmissions !== undefined) {
      this.currentConfig.minNumSubmissions = changedFields.minNumSubmissions;
    }

    if (changedFields.numReviewsPerStudent !== undefined) {
      this.currentConfig.numReviewsPerStudent =
        changedFields.numReviewsPerStudent;
    }
  }

  async save() {
    try {
      const { instructorId, cohortId } = this.rootStore.appStore;

      if (this.savedConfig.id) {
        const draftsData = await this.draftDataSource.getDrafts({
          assignmentId: this.savedConfig.id,
          cohortId,
        });

        const isConfirmed = await this.rootStore.modalStore.modal(
          ConfirmationModal,
          {
            title: 'Be careful!',
            message:
              draftsData.length > 0
                ? `${draftsData.length} of the students ${
                    draftsData.length === 1 ? 'has' : 'have'
                  } already submitted their ${
                    draftsData.length === 1 ? 'draft' : 'drafts'
                  } for this assignment. Are you sure you want to edit the details of the task?`
                : 'The students may already have started working on this assignment. Are you sure you want to edit the details of the task?',
            confirmLabel: 'Yes',
            denyLabel: 'Cancel',
          }
        );

        if (!isConfirmed) return;
      }

      const criteriaData = this.criteriaManager.toCriteriaData();

      runInAction(() => {
        this.savedConfig.title = this.currentConfig.title;
        this.savedConfig.summary = this.currentConfig.summary;
        this.savedConfig.criteria = criteriaData;
        this.savedConfig.minNumSubmissions =
          this.currentConfig.minNumSubmissions;
        this.savedConfig.numReviewsPerStudent =
          this.currentConfig.numReviewsPerStudent;

        this.isChanged = false;
        this.criteriaManager.resetIsChangedFlag();
      });

      const payload = {
        id: this.savedConfig.id,
        title: this.savedConfig.title,
        summary: this.savedConfig.summary,
        criteria: criteriaData,
        minNumSubmissions: this.savedConfig.minNumSubmissions,
        numReviewsPerStudent: this.savedConfig.numReviewsPerStudent,
      };

      const data = payload.id
        ? await this.assignmentDataSource.updateAssignment(
            payload,
            this.savedConfig.id,
            instructorId
          )
        : await this.assignmentDataSource.createAssignment(
            payload,
            instructorId
          );

      runInAction(() => {
        this.savedConfig.id = data.id;

        this.rootStore.notificationStore.addNotification(
          'Assignment configuration has been saved.',
          {
            type: NotificationType.success,
          }
        );
      });
    } catch (err) {
      runInAction(() => {
        this.isChanged = true;

        this.rootStore.notificationStore.addNotification(
          'Something went wrong while saving the configuration.',
          { type: NotificationType.error }
        );
      });
    }
  }

  discard() {
    this.currentConfig.title = this.savedConfig.title;
    this.currentConfig.summary = this.savedConfig.summary;
    this.currentConfig.criteria = this.savedConfig.criteria;
    this.currentConfig.minNumSubmissions = this.savedConfig.minNumSubmissions;
    this.currentConfig.numReviewsPerStudent =
      this.savedConfig.numReviewsPerStudent;
    this.criteriaManager.resetIsChangedFlag();
    this.criteriaManager.reinitializeCriteria(this.savedConfig.criteria);

    this.isChanged = false;
  }

  get isDataChanged() {
    return this.isChanged || this.criteriaManager.isDataChanged;
  }

  async loadAssignmentConfig(id: string) {
    if (!id) return;

    this.isReady = false;
    const data = await this.assignmentDataSource.getAssignment(id);
    const sortedCriteria = data.criteria.map((c) => ({
      ...c,
      ranks: c.ranks.sort((a, b) => b.gradeWeight - a.gradeWeight),
    }));

    runInAction(() => {
      this.currentConfig.id = data.id;
      this.currentConfig.title = data.title;
      this.currentConfig.summary = data.summary;
      this.currentConfig.criteria = sortedCriteria;
      this.currentConfig.minNumSubmissions = data.minNumSubmissions;
      this.currentConfig.numReviewsPerStudent = data.numReviewsPerStudent;
      this.savedConfig.id = data.id;
      this.savedConfig.title = data.title;
      this.savedConfig.summary = data.summary;
      this.savedConfig.criteria = sortedCriteria;
      this.savedConfig.minNumSubmissions = data.minNumSubmissions;
      this.savedConfig.numReviewsPerStudent = data.numReviewsPerStudent;
      this.criteriaManager.reinitializeCriteria(data.criteria);
      this.isReady = true;
    });
  }
}
