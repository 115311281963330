import React, { FC, RefObject } from 'react';
import classNames from 'classnames';

interface ITextarea {
  label?: string;
  description?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  inputClassName?: string;
  value: string;
  inputRef?: RefObject<HTMLTextAreaElement>;

  onChange(value: string): void;
  onBlur?(): void;
}

export const Textarea: FC<ITextarea> = ({
  label,
  description,
  placeholder,
  required = true,
  disabled,
  value,
  onChange,
  onBlur,

  className,
  inputClassName,
  inputRef: ref,
}) => (
  <label className={classNames('w-full flex flex-col', className)}>
    <div>
      <span className="font-semibold text-black">{label}</span>
      {!required && (
        <span className="text-gray-700 font-normal italic"> - Optional</span>
      )}
    </div>
    <span className="text-sm text-gray-700 font-normal">{description}</span>

    <textarea
      className={classNames(
        'focus:outline-none focus:ring-2 focus:ring-keyboardCue border px-3 py-1 text-md font-normal placeholder:italic',
        disabled && 'cursor-not-allowed opacity-70',
        inputClassName
          ? inputClassName
          : 'rounded-md border-gray-500 min-h-[34px]'
      )}
      ref={ref}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
    />
  </label>
);
