import React, { FC, KeyboardEvent } from 'react';
import { IAssignmentCriterion, IRankScore } from '../../stores/domain';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Icon } from '../Icon';

interface IReviewCriteriaProps {
  criterion: IAssignmentCriterion;
  ranks: IRankScore[];

  onRankSelected?(rank: IRankScore): void;
}

export const ReviewCriteria: FC<IReviewCriteriaProps> = observer(
  ({ criterion, ranks, onRankSelected }) => {
    const handleKeyDown = (
      e: KeyboardEvent<HTMLDivElement>,
      { rankId, criteriaId, score }: IRankScore
    ) => {
      e.stopPropagation();

      if ((e.code === 'Enter' || e.code === 'Space') && onRankSelected) {
        onRankSelected({
          rankId,
          criteriaId,
          score,
        });
      }
    };

    const isRankSelected = (rankId: string) => {
      return ranks.some((r) => r.rankId === rankId);
    };

    return (
      <div className="flex overflow-x-auto">
        {criterion.ranks.map(({ id, description, name, gradeWeight }) => {
          const isRankHighlighted = isRankSelected(id);

          return (
            <div
              key={id}
              className={classNames(
                'relative h-[200px] flex-1 flex flex-col border-gray-500 border-y border-r first:border-l first:rounded-l-md last:rounded-r-md focus:outline-none min-w-[150px]  overflow-hidden p-[4px] [&>h6]:first:rounded-tl-md [&>h6]:last:rounded-tr-md',
                isRankHighlighted && 'bg-success-light',
                onRankSelected && 'cursor-pointer focus:shadow-focus'
              )}
              onClick={() =>
                onRankSelected &&
                onRankSelected({
                  rankId: id,
                  criteriaId: criterion.id,
                  score: gradeWeight,
                })
              }
              onKeyDown={(e) =>
                handleKeyDown(e, {
                  rankId: id,
                  criteriaId: criterion.id,
                  score: gradeWeight,
                })
              }
              role="tab"
              tabIndex={onRankSelected && 0}
              aria-label="Tab Button"
            >
              {isRankHighlighted && (
                <Icon
                  id="verified"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[60px] text-white text-[160px]"
                />
              )}
              <h6
                className={classNames(
                  'z-10 flex items-center p-2 w-full bg-gray-200 font-semibold',
                  isRankHighlighted && 'bg-success'
                )}
              >
                {name}
              </h6>
              <div className="z-10 p-2 overflow-y-auto text-sm">
                {description}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
);
