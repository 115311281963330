import React, {
  CSSProperties,
  FC,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { isContentEmpty } from '../../utils/is-editor-empty';
import ReactQuill from 'react-quill';
import { INote } from '../../stores/domain';
import classNames from 'classnames';

interface IDraftProps {
  content: string;
  title?: string;
  style?: CSSProperties;
  currentNoteIndex?: number;
  notes?: INote[];

  changeCurrentNoteIndex?(index: number): void;
  onSelection?(selection: ReactQuill.Range): void;
}

export const Draft: FC<IDraftProps> = ({
  content,
  title = 'Draft',
  style,
  currentNoteIndex = 0,
  notes,
  changeCurrentNoteIndex,
  onSelection,
}) => {
  const [areMarkersHovered, setAreMarkersHovered] = useState(false);
  const quillRef = useRef<ReactQuill>(null);
  const [editorWidth, setEditorWidth] = useState(0);
  const [markers, setMarkers] = useState<(JSX.Element | null)[]>([]);

  useLayoutEffect(() => {
    const q = quillRef.current?.getEditor();
    const note = notes?.[currentNoteIndex];

    if (q && note) {
      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const width = Math.floor(entry.target.clientWidth);

          setEditorWidth(width);
        });
      });

      observer.observe(q.root);
      q.formatText(note.startIndex, note.length, {
        'background-color': 'yellow',
      });
    }
  }, [notes, currentNoteIndex, areMarkersHovered, markers]);

  useEffect(() => {
    const renderMarker = (note: INote, index: number) => {
      const q = quillRef.current?.getEditor();

      if (!q || !note || note.length === 0) {
        return null;
      }

      const bounds = q.getBounds(note.startIndex);
      const top = bounds.top || 0;
      const numberOfMarkersAtTheSameHeight = (notes || []).filter(
        (n, i) =>
          index > i && n.length > 0 && q.getBounds(n.startIndex).top === top
      ).length;
      const right =
        -6 - numberOfMarkersAtTheSameHeight * (areMarkersHovered ? 20 : 4);

      return (
        <button
          key={`marker-${index}`}
          className={classNames(
            'absolute overflow-hidden rounded-full min-w-[28px] h-[28px] text-center flex items-center justify-center font-semibold border border-black',
            index === currentNoteIndex ? 'bg-[yellow]' : 'bg-yellow-100'
          )}
          style={{ top, right }}
          disabled={!changeCurrentNoteIndex}
          onClick={() => {
            if (changeCurrentNoteIndex) {
              changeCurrentNoteIndex(index);
            }
          }}
          onPointerEnter={() => setAreMarkersHovered(true)}
          onPointerLeave={() => setAreMarkersHovered(false)}
        >
          {index + 1}
        </button>
      );
    };

    const newMarkers = (notes || []).map((note, i) => renderMarker(note, i));

    setMarkers(newMarkers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes, areMarkersHovered, currentNoteIndex, editorWidth]);

  return isContentEmpty(content) ? (
    <div className="border border-gray-500 rounded-md mb-6" style={style}>
      <div className="flex justify-between align-center gap-2 bg-gray-200 p-2 border-b border-gray-500">
        <h5 className="text-lg font-semibold ml-1">{title}</h5>
      </div>
      <div className="relative z-10">
        <ReactQuill
          ref={quillRef}
          readOnly
          value={content}
          modules={{ toolbar: false }}
          onChangeSelection={onSelection}
          className="p-2 h-full text-sm overflow-y-auto"
        />
        {markers}
      </div>
    </div>
  ) : null;
};
