import React from 'react';
import { observer } from 'mobx-react-lite';

import { Header } from '../../components/Header/Header';
import { useStores } from '../../providers/store/use-stores';
import { DraftSelection } from './DraftSelection';
import { DraftEditView } from './DraftEditView';
import { ReviewSelection } from './ReviewSelection';
import { ReviewView } from './ReviewView';
import { getFullscreenAction } from '../../components/Header/actions';
import { DraftOverviewView } from './DraftOverviewView';
import { ActiveView } from '../../stores/domain';

export const StudentView = observer(() => {
  const {
    fullscreenStore,
    assignmentConfigStore: {
      savedConfig: { title, summary },
    },
    solutionDraftStore: { activeView, setActiveView },
    solutionReviewStore: { activeReview, setActiveReview },
  } = useStores();

  if (activeView === ActiveView.DraftOverview) {
    return <DraftOverviewView goBack={() => setActiveView(ActiveView.None)} />;
  }

  if (activeView === ActiveView.DraftEdit) {
    return <DraftEditView goBack={() => setActiveView(ActiveView.None)} />;
  }

  if (activeReview) {
    return (
      <ReviewView
        activeReview={activeReview}
        goBack={() => setActiveReview(null)}
      />
    );
  }

  return (
    <div className="w-full h-full bg-white">
      <Header title={title} action={getFullscreenAction(fullscreenStore)} />
      <div className="h-full p-6 flex flex-col gap-lg">
        <p>{summary}</p>
        <DraftSelection />
        <ReviewSelection />
      </div>
    </div>
  );
});
