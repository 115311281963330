import { computed, makeObservable } from 'mobx';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { IRootStore, ISolutionStore } from './types';
import { ICapiModel } from '../capi';

export class SolutionStore
  extends CapiBoundStore<ICapiModel>
  implements ISolutionStore
{
  private readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);
    this.rootStore = rootStore;

    makeObservable(this, {
      isSubmitted: computed,
    });

    this.synchronizeToCapi('isSubmitted', 'Sim.Submitted');
  }

  get isSubmitted() {
    return (
      this.rootStore.solutionDraftStore.isDraftSubmitted &&
      this.rootStore.solutionReviewStore.areReviewsSubmitted
    );
  }
}
