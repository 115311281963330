import React, { FC } from 'react';
import { Spinner } from '../../components/Spinner/Spinner';

interface ILoadingScreenProps {
  label?: string;
}

export const LoadingScreen: FC<ILoadingScreenProps> = ({
  label = 'The app is loading. Please wait.',
}) => (
  <div className="h-screen w-screen relative">
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <Spinner scale={2.5} />
      <div className="mt-4 font-semibold text-center">{label}</div>
    </div>
  </div>
);
