import { IFileDataSource } from './types';

export const API_KEY = 'super-secret';
export interface IAttachmentUploadUrlResponse {
  key: string;
  uploadUrl: string;
  bucketUrl: string;
}

export class FileDataSource implements IFileDataSource {
  constructor(private readonly apiRoot: string) {}

  async getAttachmentUploadUrl(
    assignmentId: string,
    file: File
  ): Promise<IAttachmentUploadUrlResponse> {
    const payload = {
      toolId: 'asu-peer-review-tool',
      assignmentId: assignmentId,
      filename: file.name,
      mime: file.type,
    };

    const response = await fetch(this.apiRoot, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
      body: JSON.stringify(payload),
    });

    return response.json();
  }

  async uploadAttachment(url: string, file: File) {
    await fetch(url, {
      method: 'PUT',
      body: file,
    });
  }
}
