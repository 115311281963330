import React, { FC } from 'react';
import { IGenericModal } from 'asu-sim-toolkit';
import {
  Button,
  buttonPrimaryClasses,
  buttonSecondaryClasses,
} from '../Button/Button';
import classNames from 'classnames';

interface IConfirmationModalProps extends IGenericModal<boolean> {
  title?: string;
  message: string;
  confirmLabel?: string;
  denyLabel?: string;
}

export const ConfirmationModal: FC<IConfirmationModalProps> = ({
  title,
  message,
  setOverlayClickHandler,
  onResolve,
  confirmLabel = 'Yes',
  denyLabel = 'No',
}) => {
  setOverlayClickHandler(() => onResolve(false));

  return (
    <div className="bg-white rounded-md shadow-outer flex flex-col overflow-hidden w-[450px] max-w-[100vw]">
      {title && (
        <div className="font-semibold bg-orange text-white p-md">{title}</div>
      )}
      <div className="text-md p-md">{message}</div>
      <div className="flex gap-md p-md border-t border-gray-200">
        <Button
          className={classNames(buttonSecondaryClasses, 'flex-1')}
          autoFocus
          onClick={() => onResolve(false)}
        >
          {denyLabel}
        </Button>
        <Button
          className={classNames(buttonPrimaryClasses, 'flex-1')}
          onClick={() => onResolve(true)}
        >
          {confirmLabel}
        </Button>
      </div>
    </div>
  );
};
