export interface IBaseAssignmentConfig {
  id: string;
  title: string;
  summary?: string;
}

export type AssignmentId = IBaseAssignmentConfig['id'];

export type DraftStatus = 'not-begun' | 'in-progress' | 'submitted'; // not-begun can be a virtual state and not exist in the database;
export type ReviewStatus = 'not-begun' | 'in-progress' | 'submitted';

export interface IAssignmentConfig extends IBaseAssignmentConfig {
  criteria: IAssignmentCriterion[];
  minNumSubmissions?: number;
  numReviewsPerStudent?: number;
}

export interface IRubricRank {
  id: string;
  label: string;
  points: number;
}

export interface IAssigmentRank {
  id: string;
  name: string;
  description: string;
  gradeWeight: number;
}

export interface IAssignmentCriterion {
  id: string;
  name: string;
  gradeWeight: number;
  ranks: IAssigmentRank[];
}

export interface IDraft {
  id: string;
  assignmentId: string;
  cohortId: string;
  userId: string;
  status: DraftStatus;
  filePath?: string;
  content: string;
  numberOfSubmittedReviews: number;
  numberOfAllReviews: number;
}

export interface IRankScore {
  rankId: string;
  criteriaId: string;
  score: number;
}

export interface INote {
  id: string;
  criteriaId: string;
  content: string;
  startIndex: number;
  length: number;
}

export interface IReviewData {
  id: string;
  draftId: string;
  ranks: IRankScore[];
  score: number;
  notes: INote[];
  status: ReviewStatus;
}

export interface IReview extends IReviewData {
  userId: string;
  assignmentId: string;
}

export interface IReviewGiven extends IReview {
  content: string;
  filePath?: string;
}

export enum RequestStatus {
  Idle = 'idle',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export interface DraftQueryParams {
  assignmentId?: string;
  userId?: string;
  status?: DraftStatus;
}

export interface ReviewQueryParams {
  assignmentId?: string;
  draftId?: string;
  userId?: string;
  score?: number;
  status?: ReviewStatus;
}

export interface IInstructorReview {
  content: string;
  filePath?: string;
  ranks: IRankScore[];
}

export enum ActiveView {
  None,
  DraftEdit,
  DraftOverview,
}

export const DEFAULT_CRITERION = {
  id: '',
  name: 'Criterion Name',
  gradeWeight: 3,
  ranks: [
    {
      id: '',
      name: 'Excellent',
      description: '',
      gradeWeight: 10,
    },
    {
      id: '',
      name: 'Very good',
      description: '',
      gradeWeight: 7,
    },
    {
      id: '',
      name: 'Good',
      description: '',
      gradeWeight: 5,
    },
    {
      id: '',
      name: 'Fair',
      description: '',
      gradeWeight: 3,
    },
    {
      id: '',
      name: 'Poor',
      description: '',
      gradeWeight: 1,
    },
  ],
};

export const DEFAULT_RANK = {
  id: '',
  name: '',
  description: '',
  gradeWeight: 1,
};
