import { IAssignmentConfig } from '../stores/domain';

import { IAssignmentDataSource } from './types';
import {
  IGetAssignmentConfigApi,
  IGetAssignmentsConfigApi,
  IPostAssignmentConfigApi,
  IPutAssignmentConfigApi,
} from './types.rest';
import {
  mapFromAssignmentConfig,
  mapFromAssignmentCriterion,
  mapToAssignmentConfig,
} from './mappers.rest';

export class AssignmentDataSource implements IAssignmentDataSource {
  constructor(private readonly apiRoot: string) {}

  async getAssignments(): Promise<IAssignmentConfig[]> {
    const res = await fetch(`${this.apiRoot}/assignments`);

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IGetAssignmentsConfigApi['response'];

    return data.map((item) => mapToAssignmentConfig(item));
  }

  async getAssignment(
    assignmentId: IGetAssignmentConfigApi['pathParams']['assignmentId']
  ): Promise<IAssignmentConfig> {
    const res = await fetch(`${this.apiRoot}/assignments/${assignmentId}`);

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IGetAssignmentConfigApi['response'];

    return mapToAssignmentConfig(data);
  }

  async createAssignment(
    {
      title,
      summary,
      criteria,
      minNumSubmissions,
      numReviewsPerStudent,
    }: IAssignmentConfig,
    instructorId: string
  ): Promise<IAssignmentConfig> {
    const payload: IPostAssignmentConfigApi['payload'] = {
      data: {
        title,
        summary: summary || '',
        criteria: criteria.map(mapFromAssignmentCriterion),
        minNumSubmissions,
        numReviewsPerStudent,
      },
      userId: instructorId,
    };

    const res = await fetch(`${this.apiRoot}/assignments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IPostAssignmentConfigApi['response'];

    return mapToAssignmentConfig(data);
  }

  async updateAssignment(
    assignmentConfig: IAssignmentConfig,
    assignmentId: IPutAssignmentConfigApi['pathParams']['assignmentId'],
    instructorId: string
  ): Promise<IAssignmentConfig> {
    const payload: IPostAssignmentConfigApi['payload'] = {
      data: mapFromAssignmentConfig(assignmentConfig),
      userId: instructorId,
    };

    const res = await fetch(`${this.apiRoot}/assignments/${assignmentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IPutAssignmentConfigApi['response'];

    return mapToAssignmentConfig(data);
  }
}
