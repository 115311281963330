import React, { useEffect, useState } from 'react';
import { observer, useObserver } from 'mobx-react-lite';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { RubricCriteria as ActiveRubricCriteria } from '../RubricCriteria/RubricCriteria';
import { DraggableTabButton } from '../TabButton/DraggableTabButton';
import { IconButton } from '../IconButton/IconButton';
import { useStores } from '../../providers/store/use-stores';
import { MAX_CRITERIA_NUMBER } from '../../stores/config';
import { toPercentage } from '../../utils/to-percentage';

export const RubricCriteriaManager = observer(() => {
  const {
    assignmentConfigStore: { criteriaManager },
  } = useStores();
  const { criteria } = criteriaManager;
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (!criteria[activeTabIndex]) {
      setActiveTabIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria.length]);

  const changeTab = (id: string) => {
    const tabIndex = criteria.findIndex((t) => t.id === id);

    if (tabIndex > -1) {
      setActiveTabIndex(tabIndex);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const tabsCopy = [...criteria];
    const [reorderedItem] = tabsCopy.splice(result.source.index, 1);
    tabsCopy.splice(result.destination.index, 0, reorderedItem);

    const activeTabId = criteria[activeTabIndex].id;
    const tabIndex = tabsCopy.findIndex((t) => t.id === activeTabId);

    criteriaManager.setCriteria(tabsCopy);
    setActiveTabIndex(tabIndex);
  };

  const activeCriteria = criteria[activeTabIndex] || criteria[0];

  return (
    <div className="w-full flex flex-col mb-6">
      <div className="flex items-center justify-between gap-sm w-full">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId="droppable-tab-button-list"
            direction="horizontal"
          >
            {(provided) =>
              useObserver(() => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="min-h-[48px] flex flex-1 items-end h-auto overflow-x-auto overflow-y-clip -mb-[1px] z-0"
                >
                  {criteria.map(({ name, gradeWeight, id }, index) => (
                    <DraggableTabButton
                      isActive={index === activeTabIndex}
                      index={index}
                      key={id}
                      id={id}
                      onClick={() => changeTab(id)}
                      gradeWeight={`${toPercentage(
                        gradeWeight,
                        criteriaManager.allGradesWeight
                      )}%`}
                    >
                      {name}
                    </DraggableTabButton>
                  ))}
                  {provided.placeholder}
                </div>
              ))
            }
          </Droppable>
        </DragDropContext>
        <IconButton
          iconId="plus"
          id="add-new-criteria"
          label="Add new criteria"
          className="mr-1 bg-orange text-white hover:bg-orange"
          onClick={() => criteriaManager.addCriterion()}
          disabled={criteria.length >= MAX_CRITERIA_NUMBER}
        />
      </div>
      <div className="p-2 rounded-b-md rounded-tr-md border border-gray-500">
        <ActiveRubricCriteria
          criteriaTab={activeCriteria}
          criteriaLength={criteria.length}
          onRemove={criteriaManager.removeCriteria}
          onAdd={criteriaManager.addCriterion}
        />
      </div>
    </div>
  );
});
