import React, { FC, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import { IconButton, IconButtonPrimaryClasses } from '../IconButton/IconButton';
import { Range } from 'react-quill';
import { Textarea } from '../Textarea/Textarea';
import { AttachedFile } from './AttachedFile';
import { Draft } from './Draft';
import { IAssignmentCriterion, INote } from '../../stores/domain';

interface IReviewProps {
  isViewOnly?: boolean;
  content: string;
  filePath?: string;
  noteIndex: number;
  criteriaNotes: INote[];
  generalNote?: INote;
  activeCriterion: IAssignmentCriterion;
  selectedRange?: Range;

  onChangeNoteIndex(index: number): void;
  onSelection?(selection: Range): void;
  onUpdateNote?(id: string, content: string): void;
  onAddNote?({
    criteriaId,
    startIndex,
    length,
  }: {
    criteriaId: string;
    startIndex?: number | undefined;
    length?: number | undefined;
  }): void;
  onRemoveNote?(id: string): void;
}

export const Review: FC<IReviewProps> = ({
  isViewOnly = false,
  content,
  filePath,
  noteIndex,
  criteriaNotes,
  generalNote,
  activeCriterion,
  selectedRange,
  onChangeNoteIndex,
  onSelection,
  onUpdateNote,
  onAddNote,
  onRemoveNote,
}) => {
  const noteArea = useRef<HTMLTextAreaElement>(null);

  const activeNote = criteriaNotes.find((_, index) => index === noteIndex);

  const prevRange = useRef<Range | undefined>(selectedRange);

  useLayoutEffect(() => {
    if (
      isViewOnly ||
      selectedRange === prevRange.current ||
      selectedRange === null ||
      selectedRange?.length === 0
    ) {
      return;
    }

    onAddNote?.({
      criteriaId: activeCriterion.id,
      startIndex: selectedRange?.index,
      length: selectedRange?.length,
    });
    onChangeNoteIndex(criteriaNotes.length);
    setTimeout(() => noteArea.current?.focus(), 100);

    prevRange.current = selectedRange;
  }, [
    selectedRange,
    prevRange,
    isViewOnly,
    onAddNote,
    activeCriterion.id,
    onChangeNoteIndex,
    criteriaNotes.length,
  ]);

  const handleNoteBlur = () => {
    if (!activeNote) return;

    if (!activeNote.content.length) {
      onRemoveNote?.(activeNote.id);
    }
  };

  const noteLabel = activeNote
    ? `#${noteIndex + 1} note for "${activeCriterion.name}"`
    : `Note for "${activeCriterion.name}"`;

  const showGeneralNote =
    generalNote &&
    (!isViewOnly || (isViewOnly && generalNote.content.length > 0));
  const showSpecificNotes =
    !isViewOnly || (isViewOnly && criteriaNotes.length > 0);

  return (
    <div className="min-h-[400px] flex border border-gray-500 rounded-md overflow-hidden mb-2">
      <div
        className={classNames(
          'h-full',
          showGeneralNote || showSpecificNotes ? 'w-2/3' : 'w-full'
        )}
      >
        <Draft
          content={content}
          title="Your draft"
          style={{ borderRadius: 0, border: 0, marginBottom: 0 }}
          onSelection={onSelection}
          changeCurrentNoteIndex={onChangeNoteIndex}
          currentNoteIndex={noteIndex}
          notes={criteriaNotes}
        />
        <AttachedFile
          filePath={filePath}
          style={{
            height: 800,
            borderRadius: 0,
            border: 0,
            marginBottom: 0,
          }}
        />
      </div>
      <div
        className={classNames(
          'w-1/3 flex flex-col bg-gray-200 border-l border-gray-500',
          !(showGeneralNote || showSpecificNotes) && 'hidden'
        )}
      >
        {showGeneralNote && (
          <>
            <div className="flex gap-2 justify-between align-center p-2 border-b border-gray-500">
              <div className="flex gap-1 items-center truncate">
                <h5 className="text-lg font-semibold truncate">
                  General Feedback
                </h5>
              </div>
            </div>
            <Textarea
              className={classNames(
                'p-1',
                isViewOnly && criteriaNotes.length === 0 && 'flex-1'
              )}
              inputClassName="h-full border-gray-500 rounded-md disabled:bg-white disabled:opacity-80"
              placeholder="Write overall feedback here"
              disabled={isViewOnly}
              value={generalNote.content}
              onChange={(v) =>
                onUpdateNote && onUpdateNote?.(generalNote.id, v)
              }
            />
          </>
        )}
        {showSpecificNotes && (
          <>
            <div
              className={classNames(
                'flex gap-2 justify-between align-center p-2 border-gray-500',
                showGeneralNote ? 'border-y' : 'border-b'
              )}
            >
              <div className="flex gap-1 items-center truncate">
                <h5 className="text-lg font-semibold truncate">{noteLabel}</h5>
                {activeNote && onRemoveNote && (
                  <IconButton
                    iconId="close"
                    id="remove-note"
                    label="Remove this note"
                    containerClassName="p-[2px]"
                    className="text-orange h-full"
                    onClick={() => {
                      onRemoveNote(activeNote.id);
                      onChangeNoteIndex(0);
                    }}
                  />
                )}
              </div>
              <div className="flex gap-1">
                <IconButton
                  iconId="chevron-left"
                  disabled={noteIndex === 0}
                  onClick={() => onChangeNoteIndex(noteIndex - 1)}
                  className={classNames(IconButtonPrimaryClasses, 'h-full')}
                />
                <IconButton
                  iconId="chevron-right"
                  disabled={
                    criteriaNotes.length === 0 ||
                    criteriaNotes.length - 1 === noteIndex
                  }
                  onClick={() => onChangeNoteIndex(noteIndex + 1)}
                  className={classNames(IconButtonPrimaryClasses, 'h-full')}
                />
              </div>
            </div>
            <Textarea
              className="flex-1 p-1"
              inputRef={noteArea}
              disabled={isViewOnly || !activeNote}
              inputClassName="h-full border-gray-500 rounded-md disabled:bg-white disabled:opacity-80"
              value={activeNote?.content || ''}
              placeholder="Comment on selected fragment here"
              onChange={(v) => {
                activeNote && onUpdateNote?.(activeNote.id, v);
              }}
              onBlur={handleNoteBlur}
            />
          </>
        )}
      </div>
    </div>
  );
};
