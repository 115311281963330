import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { TextInput } from '../TextInput/TextInput';
import { NumberInput } from '../NumberInput/NumberInput';
import { IconButton, IconButtonPrimaryClasses } from '../IconButton/IconButton';
import { RubricRank } from '../RubricRank/RubricRank';
import { ICriteriaTab } from '../../stores/types';
import { MAX_RANK_NUMBER } from '../../stores/config';
import { IAssignmentCriterion } from '../../stores/domain';

interface IRubricCriteriaProps {
  criteriaTab: ICriteriaTab;
  criteriaLength: number;

  onAdd(data?: IAssignmentCriterion): void;
  onRemove(criterionId: string): void;
}

export const RubricCriteria: FC<IRubricCriteriaProps> = observer(
  ({ criteriaTab, criteriaLength, onAdd, onRemove }) => {
    const {
      id,
      name,
      gradeWeight,
      ranks,
      updateData,
      removeRank,
      addRank,
      sortRanksByGradesWeight,
    } = criteriaTab;

    return (
      <div className="w-full flex flex-col gap-2">
        <div className="flex w-full justify-between items-end gap-2">
          <div className="flex items-end gap-2">
            <TextInput
              label="Name"
              className="w-[200px]"
              value={name}
              onChange={(v) => updateData({ name: v })}
            />
            <NumberInput
              label="Grade Weight"
              className="w-[130px]"
              value={gradeWeight}
              onChange={(v) => updateData({ gradeWeight: v })}
            />
            <IconButton
              iconId="copy"
              id={`duplicate-${id}-criteria`}
              label={`Duplicate '${name}' criteria`}
              className={IconButtonPrimaryClasses}
              onClick={() => onAdd(criteriaTab.toJS(true))}
            />
            <IconButton
              iconId="trash"
              id={`remove-${id}-criteria`}
              label={`Remove '${name}' criteria`}
              className={IconButtonPrimaryClasses}
              onClick={() => onRemove(id)}
              disabled={criteriaLength < 2}
            />
          </div>
          <IconButton
            iconId="plus"
            id={`add-new-rank`}
            label={`Add new rank`}
            disabledLabel={`You cannot add more than ${MAX_RANK_NUMBER} grades`}
            className="mr-1 bg-orange text-white hover:bg-orange"
            onClick={addRank}
            disabled={ranks.length >= MAX_RANK_NUMBER}
          />
        </div>
        <div className="w-full min-h-[400px] flex gap-2 overflow-x-auto">
          {ranks.map((r) => (
            <RubricRank
              key={r.id}
              criteriaRank={r}
              ranksLength={ranks.length}
              onRemove={removeRank}
              sortRanks={sortRanksByGradesWeight}
            />
          ))}
        </div>
      </div>
    );
  }
);
