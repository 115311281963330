import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';
import { Button, buttonPrimaryClasses } from '../../components/Button/Button';
import classNames from 'classnames';
import { Table } from '../../components/Table/Table';
import { ActiveView, RequestStatus } from '../../stores/domain';
import { Spinner } from '../../components/Spinner/Spinner';
import { draftStatusLabels } from '../../utils/enum-mappers';

export const DraftSelection = observer(() => {
  const {
    solutionDraftStore: {
      id,
      status,
      numberOfSubmittedReviews,
      numberOfAllReviews,
      requestStatus,
      setActiveView,
      addDraft,
      fetchDraft,
    },
  } = useStores();

  useEffect(() => {
    fetchDraft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const draftPlaceholder = [
    [
      {
        data: id ? draftStatusLabels[status] : draftStatusLabels['not-begun'],
      },
      {
        data:
          numberOfAllReviews > 0
            ? `${numberOfSubmittedReviews}/${numberOfAllReviews}`
            : '0',
      },
      {
        data: id ? (
          status === 'submitted' ? (
            <Button
              className={classNames(buttonPrimaryClasses, 'ml-auto text-xs')}
              onClick={() => {
                setActiveView(ActiveView.DraftOverview);
              }}
            >
              See Reviews
            </Button>
          ) : (
            <Button
              className={classNames(buttonPrimaryClasses, 'ml-auto text-xs')}
              onClick={() => {
                setActiveView(ActiveView.DraftEdit);
              }}
            >
              Edit draft
            </Button>
          )
        ) : (
          <Button
            className={classNames(buttonPrimaryClasses, 'ml-auto text-xs')}
            onClick={addDraft}
            disabled={requestStatus === RequestStatus.Loading}
          >
            Begin Draft
          </Button>
        ),
      },
    ],
  ];

  return (
    <div>
      <div className="text-lg font-semibold mb-4">
        Write or Upload Your Draft
      </div>
      {requestStatus === RequestStatus.Loading ? (
        <Spinner />
      ) : (
        <Table
          columns={[
            { data: 'Status' },
            { data: 'Reviews' },
            { data: '', className: 'text-right w-full' },
          ]}
          rows={draftPlaceholder}
        />
      )}
    </div>
  );
});
