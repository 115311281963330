import { IFullscreenStore, isSafari } from 'asu-sim-toolkit';
import { IconId } from '../../icon-definitions';

export const getFullscreenAction = (fullscreenStore: IFullscreenStore) =>
  fullscreenStore.fullscreenControlState.isVisible && !isSafari
    ? {
        iconId: (fullscreenStore.isFullscreen
          ? 'fullscreen_exit'
          : 'fullscreen') as IconId,
        disabled: !fullscreenStore.fullscreenControlState.isEnabled,
        onClick: fullscreenStore.toggleFullscreen,
      }
    : undefined;
