import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import {
  INotification,
  NotificationStage,
  NotificationType,
} from 'asu-sim-toolkit';

interface INotificationItem {
  notification: INotification;
}

const stageStyles: Record<NotificationStage, string> = {
  [NotificationStage.in]: 'notification-fade-in',
  [NotificationStage.out]: 'notification-fade-out',
};

const typeStyles: Record<NotificationType, string> = {
  [NotificationType.info]: 'bg-orange',
  [NotificationType.warning]: 'bg-warning',
  [NotificationType.error]: 'bg-error-intense',
  [NotificationType.success]: 'bg-success',
};

export const NotificationItem: FC<INotificationItem> = observer(
  ({ notification }) => {
    const { text, type, stage, iteration } = notification;

    const classes = classNames(
      'max-w-[400px] p-4 rounded-md text-white m-4',
      stageStyles[stage],
      typeStyles[type]
    );

    return (
      <div className={classes}>
        {text}
        {iteration > 1 && <span> ({iteration})</span>}
      </div>
    );
  }
);
