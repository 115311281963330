import classNames from 'classnames';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

interface INumberInput {
  label?: string;
  description?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  value: number;
  min?: number;
  max?: number;
  className?: string;
  inputClassName?: string;

  onChange(value: number): void;
  onBlur?(value: number): void;
}

export const NumberInput: FC<INumberInput> = ({
  label,
  description,
  placeholder,
  required = true,
  disabled,
  value,
  onChange,
  onBlur,
  min,
  max,
  className,
  inputClassName,
}) => {
  const [displayedValue, setDisplayedValue] = useState(String(value));

  useEffect(() => {
    if (value !== Number(displayedValue)) {
      setDisplayedValue(String(value));
    }
  }, [displayedValue, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const result = e.target.value.replace(/[^0-9]|^0+(?!$)/g, '');

    onChange(Number(result));
    setDisplayedValue(result);
  };

  const handleBlur = () => {
    if (displayedValue === '' || (min && value < min)) {
      setDisplayedValue(String(min || 0));
      onChange(min || 0);
    } else if (max && value > max) {
      onChange(max);
      setDisplayedValue(String(max));
    }

    if (onBlur) {
      onBlur(Number(value));
    }
  };

  return (
    <label
      className={classNames('flex flex-col', className ? className : 'w-full')}
    >
      <div>
        <span className="font-semibold text-black">{label}</span>
        {!required && (
          <span className="text-gray-700 font-normal italic"> - Optional</span>
        )}
      </div>
      <span className="text-sm text-gray-700 font-normal">{description}</span>

      <input
        className={classNames(
          'focus:outline-none focus:ring-2 focus:ring-keyboardCue w-full rounded-md border border-gray-500 px-3 py-1 text-md font-normal placeholder:italic',
          inputClassName ? inputClassName : 'text-md',
          disabled && 'cursor-not-allowed opacity-70'
        )}
        value={displayedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
      />
    </label>
  );
};
