import React from 'react';
import { observer } from 'mobx-react-lite';
import { Header } from '../../components/Header/Header';
import { TextInput } from '../../components/TextInput/TextInput';
import { Textarea } from '../../components/Textarea/Textarea';
import { useStores } from '../../providers/store/use-stores';
import { IBaseAssignmentConfig } from '../../stores/domain';
import { Button, buttonSecondaryClasses } from '../../components/Button/Button';
import { InstructorViewSection } from './InstructorViewSection';
import { RubricCriteriaManager } from '../../components/RubricCriteriaManager/RubricCriteriaManager';
import { NumberInput } from '../../components/NumberInput/NumberInput';

export const InstructorView = observer(() => {
  const {
    assignmentConfigStore: {
      currentConfig: {
        title,
        summary,
        numReviewsPerStudent,
        minNumSubmissions,
      },
      discard,
      isDataChanged,
      save,
      updateData,
      criteriaManager: { maxScore },
    },
  } = useStores();
  const updateField =
    <T extends keyof IBaseAssignmentConfig>(key: T) =>
    (newValue: IBaseAssignmentConfig[T]) =>
      updateData({ [key]: newValue });

  return (
    <div className="w-full h-full bg-white">
      <Header title={title || 'Configure assignment'}>
        <div className="flex gap-3">
          <Button
            className={buttonSecondaryClasses}
            onClick={discard}
            disabled={!isDataChanged}
          >
            Discard
          </Button>
          <Button onClick={save} disabled={!isDataChanged}>
            Save
          </Button>
        </div>
      </Header>
      <div className="overflow-y-auto">
        <InstructorViewSection title="Assignment Details">
          <TextInput
            label="Title"
            onChange={updateField('title')}
            value={title}
          />
          <Textarea
            label="Summary"
            onChange={updateField('summary')}
            value={summary || ''}
            required={false}
          />

          <NumberInput
            description="Max reviews per draft"
            onChange={(v) => updateData({ numReviewsPerStudent: v })}
            value={numReviewsPerStudent || 0}
          />
          <NumberInput
            description="Minimum peer submissions before allocating reviews"
            onChange={(v) => updateData({ minNumSubmissions: v })}
            value={minNumSubmissions || 0}
          />
        </InstructorViewSection>
        <InstructorViewSection
          title="Rubric Criteria"
          additionalText={`(Current max score: ${maxScore})`}
        >
          <RubricCriteriaManager />
        </InstructorViewSection>
      </div>
    </div>
  );
});
