import classNames from 'classnames';
import React, { ChangeEvent, FC, RefObject, KeyboardEvent } from 'react';

interface ITextInput {
  label?: string;
  description?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  value: string;
  autoFocus?: boolean;
  className?: string;
  inputClassName?: string;
  inputRef?: RefObject<HTMLInputElement>;

  onChange(value: string, event: ChangeEvent<HTMLInputElement>): void;
  onFocus?(value: string): void;
  onBlur?(value: string): void;
  onKeyDown?(e: KeyboardEvent<HTMLInputElement>): void;
}

export const TextInput: FC<ITextInput> = ({
  label,
  description,
  placeholder,
  required = true,
  disabled,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  autoFocus = false,
  className,
  inputClassName,
  inputRef,
}) => (
  <label
    className={classNames('flex flex-col', className ? className : 'w-full')}
  >
    <div>
      <span className="font-semibold text-black">{label}</span>
      {!required && (
        <span className="text-gray-700 font-normal italic"> - Optional</span>
      )}
    </div>
    <span className="text-sm text-gray-700 font-normal">{description}</span>

    <input
      ref={inputRef}
      className={classNames(
        'focus:outline-none focus:ring-2 focus:ring-keyboardCue truncate placeholder:italic rounded-md border border-gray-500 px-3 py-1 font-normal',
        inputClassName ? inputClassName : 'text-md',
        disabled && 'cursor-not-allowed opacity-70'
      )}
      value={value}
      onChange={(e) => onChange(e.target.value, e)}
      onBlur={onBlur ? (e) => onBlur(e.target.value) : undefined}
      onFocus={onFocus ? (e) => onFocus(e.target.value) : undefined}
      onKeyDown={onKeyDown}
      onClick={(e) => e.stopPropagation()}
      autoFocus={autoFocus}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
    />
  </label>
);
