import { DraftStatus, ReviewStatus } from '../stores/domain';

export const draftStatusLabels: Record<DraftStatus, string> = {
  'not-begun': 'Not begun',
  'in-progress': 'In progress',
  submitted: 'Submitted',
};

export const reviewStatusLabels: Record<ReviewStatus, string> = {
  'not-begun': 'Not begun',
  'in-progress': 'In progress',
  submitted: 'Submitted',
};
