import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import { observer } from 'mobx-react-lite';
import { Icon } from '../Icon';

export interface IReviewTabButtonProps extends PropsWithChildren {
  id: string;
  children: string;
  isActive: boolean;
  isVerified: boolean;

  onClick(): void;
}

export const ReviewTabButton: FC<IReviewTabButtonProps> = observer(
  ({ children, id, isActive = false, isVerified = false, onClick }) => {
    return (
      <button
        key={id}
        className={classNames(
          'first:border-l focus:outline-none focus:border-l-2 focus:border-r-2 focus:border-t-2 focus:border-keyboardCue border-gray-500 border-t border-r mt-[3px] select-none cursor-pointer flex items-center justify-center rounded-t-lg text-md px-3 min-w-[100px]',
          isActive
            ? 'bg-white font-semibold py-[6px] min-h-[45px]'
            : 'bg-gray-100 min-h-[41px]'
        )}
        onClick={onClick}
        role="tab"
        aria-label="Tab Button"
      >
        {children}
        {isVerified && (
          <Icon id="verified" className="text-gray-600 ml-2 text-lg" />
        )}
      </button>
    );
  }
);
