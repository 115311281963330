import React, { FC, KeyboardEvent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import { observer } from 'mobx-react-lite';

export interface IDraggableTabButtonProps extends PropsWithChildren {
  isActive?: boolean;
  index: number;
  id: string;
  children: string;
  gradeWeight?: string;

  onClick(): void;
}

export const DraggableTabButton: FC<IDraggableTabButtonProps> = observer(
  ({ children, index, id, isActive = false, gradeWeight, onClick }) => {
    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'Enter' || e.code === 'Space') {
        onClick();
      }
    };

    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classNames(
              'first:border-l focus:outline-none focus:border-l-2 focus:border-r-2 focus:border-t-2 focus:border-keyboardCue mt-[3px] select-none cursor-pointer flex items-center justify-center rounded-t-lg text-md px-3 min-w-[100px]',
              isActive
                ? 'bg-white font-semibold py-[6px] min-h-[45px]'
                : 'bg-gray-100 min-h-[41px]',
              snapshot.isDragging
                ? 'border-keyboardCue border-2'
                : 'border-gray-500 border-t border-r'
            )}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            role="tab"
            tabIndex={0}
            aria-label="Tab Button"
          >
            <span className="mr-2 text-sm font-normal text-gray-700">
              {gradeWeight}
            </span>
            <h6 className="-mb-0.5 whitespace-nowrap inline-block select-none overflow-hidden">
              {children}
            </h6>
          </div>
        )}
      </Draggable>
    );
  }
);
