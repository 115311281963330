import { CapiBoundStore, ICAPI, NotificationType } from 'asu-sim-toolkit';
import { IRootStore, ISolutionDraftStore } from './types';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  IDraft,
  DraftStatus,
  RequestStatus,
  IReview,
  ActiveView,
} from './domain';
import { IDraftDataSource, IReviewDataSource } from '../data-sources/types';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';
import { isContentEmpty } from '../utils/is-editor-empty';
import { ICapiModel } from '../capi';
import { htmlToPlainText } from '../utils/html-to-plain-text';

export class SolutionDraftStore
  extends CapiBoundStore<ICapiModel>
  implements ISolutionDraftStore
{
  private readonly rootStore: IRootStore;
  private readonly draftDataSource: IDraftDataSource;
  private readonly reviewDataSource: IReviewDataSource;
  id: string;
  cohortId: string;
  status: DraftStatus;
  filePath?: string;
  content: string;
  reviews: IReview[];
  numberOfSubmittedReviews: number;
  numberOfAllReviews: number;

  isChanged = false;

  requestStatus = RequestStatus.Idle;
  activeView: ActiveView;

  constructor(
    rootStore: IRootStore,
    capi: ICAPI<ICapiModel>,
    draftDataSource: IDraftDataSource,
    reviewDataSource: IReviewDataSource
  ) {
    super(capi);

    this.draftDataSource = draftDataSource;
    this.reviewDataSource = reviewDataSource;
    this.rootStore = rootStore;

    this.id = '';
    this.cohortId = '';
    this.status = 'not-begun';
    this.filePath = '';
    this.content = '';
    this.numberOfSubmittedReviews = 0;
    this.numberOfAllReviews = 0;
    this.reviews = [];

    this.activeView = ActiveView.None;

    makeObservable(this, {
      requestStatus: observable,
      activeView: observable,
      isDraftSubmitted: computed,
      isChanged: observable,
      status: observable,
      filePath: observable,
      content: observable,
      canSubmit: computed,
      numberOfSubmittedReviews: observable,
      numberOfAllReviews: observable,

      updateData: action.bound,
      save: action.bound,
      submit: action.bound,
      fetchDraft: action.bound,
      setActiveView: action.bound,
      addDraft: action.bound,
      uploadFile: action.bound,
      clearFile: action.bound,
    });

    this.synchronizeToCapi('content', 'Sim.Solution.Content', htmlToPlainText);
  }

  async fetchDraft() {
    try {
      const assignmentConfig = this.rootStore.assignmentConfigStore.savedConfig;
      const { cohortId, userId } = this.rootStore.appStore;

      this.requestStatus = RequestStatus.Loading;

      const draftsData = await this.draftDataSource.getDrafts({
        userId,
        assignmentId: assignmentConfig.id,
        cohortId,
      });

      runInAction(() => {
        const data = draftsData?.[0] || null;

        if (data) {
          this.id = data.id;
          this.cohortId = data.cohortId;
          this.status = data.status;
          this.filePath = data.filePath;
          this.content = data.content;
          this.numberOfSubmittedReviews = data.numberOfSubmittedReviews;
          this.numberOfAllReviews = data.numberOfAllReviews;
        }

        this.requestStatus = RequestStatus.Success;
      });
    } catch (err) {
      runInAction(() => {
        this.rootStore.notificationStore.addNotification(
          'Something went wrong while fetching your draft.',
          { type: NotificationType.error }
        );
        this.requestStatus = RequestStatus.Error;
      });
    }
  }

  async setActiveView(activeView: ActiveView) {
    if (activeView === ActiveView.DraftOverview) {
      this.reviews = await this.reviewDataSource.getReviews({
        draftId: this.id,
        status: 'submitted',
      });
    }
    runInAction(() => {
      this.activeView = activeView;
    });
  }

  async addDraft() {
    const assignmentConfig = this.rootStore.assignmentConfigStore.savedConfig;
    const { cohortId, userId } = this.rootStore.appStore;

    const payload: IDraft = {
      id: '',
      assignmentId: assignmentConfig.id,
      cohortId,
      userId,
      filePath: '',
      content: '',
      status: 'not-begun',
      numberOfAllReviews: 0,
      numberOfSubmittedReviews: 0,
    };

    try {
      this.requestStatus = RequestStatus.Loading;

      const data = await this.draftDataSource.createDraft(payload);

      runInAction(() => {
        this.id = data.id;
        this.status = data.status;
        this.filePath = data.filePath;
        this.content = data.content;
        this.numberOfSubmittedReviews = data.numberOfSubmittedReviews;
        this.numberOfAllReviews = data.numberOfSubmittedReviews;

        this.setActiveView(ActiveView.DraftEdit);

        this.rootStore.notificationStore.addNotification(
          'Your draft has begun.',
          {
            type: NotificationType.success,
          }
        );
        this.requestStatus = RequestStatus.Success;
      });
    } catch (err) {
      runInAction(() => {
        this.rootStore.notificationStore.addNotification(
          'Something went wrong while beginning your draft.',
          { type: NotificationType.error }
        );
        this.requestStatus = RequestStatus.Error;
      });
    }
  }

  updateData(changedFields: Partial<IDraft>) {
    this.isChanged = true;

    if (changedFields.content !== undefined) {
      this.content = changedFields.content;
    }

    if (changedFields.filePath !== undefined) {
      this.filePath = changedFields.filePath;
    }
  }

  async save() {
    this.isChanged = false;
    const assignmentConfig = this.rootStore.assignmentConfigStore.savedConfig;
    const { cohortId, userId } = this.rootStore.appStore;

    const payload: IDraft = {
      id: this.id,
      assignmentId: assignmentConfig.id,
      cohortId,
      userId,
      status: 'in-progress',
      filePath: this.filePath,
      content: this.content,
      numberOfAllReviews: this.numberOfAllReviews,
      numberOfSubmittedReviews: this.numberOfAllReviews,
    };

    try {
      this.requestStatus = RequestStatus.Loading;

      const data = await this.draftDataSource.updateDraft(payload, this.id);

      runInAction(() => {
        this.id = data.id;
        this.status = data.status;

        this.rootStore.notificationStore.addNotification(
          'Your draft has been saved.',
          {
            type: NotificationType.success,
          }
        );
        this.requestStatus = RequestStatus.Success;
      });
    } catch (err) {
      runInAction(() => {
        this.rootStore.notificationStore.addNotification(
          'Something went wrong while saving the draft.',
          { type: NotificationType.error }
        );
        this.requestStatus = RequestStatus.Error;
      });
    }
  }

  async submit() {
    const assignmentConfig = this.rootStore.assignmentConfigStore.savedConfig;
    const { cohortId, userId } = this.rootStore.appStore;

    const payload: IDraft = {
      id: this.id,
      assignmentId: assignmentConfig.id,
      cohortId,
      userId,
      status: 'submitted',
      filePath: this.filePath,
      content: this.content,
      numberOfAllReviews: this.numberOfAllReviews,
      numberOfSubmittedReviews: this.numberOfAllReviews,
    };

    try {
      const isConfirmed = await this.rootStore.modalStore.modal(
        ConfirmationModal,
        {
          title: 'Are you sure?',
          message:
            'Once submitted, you cannot go back to make additional edits to your draft.',
          confirmLabel: 'Submit',
          denyLabel: 'Cancel',
        }
      );

      if (!isConfirmed) return;

      this.requestStatus = RequestStatus.Loading;

      await this.draftDataSource.updateDraft(payload, this.id);

      this.rootStore.notificationStore.addNotification(
        'You can now review your your submitted assignment',
        {
          type: NotificationType.success,
        }
      );

      runInAction(() => {
        this.isChanged = false;
        this.status = 'submitted';

        this.rootStore.notificationStore.addNotification(
          'Your draft has been submitted.',
          {
            type: NotificationType.success,
          }
        );
        this.requestStatus = RequestStatus.Success;
      });
    } catch (err) {
      runInAction(() => {
        this.rootStore.notificationStore.addNotification(
          'Something went wrong while submitting your draft.',
          { type: NotificationType.error }
        );
        this.requestStatus = RequestStatus.Error;
      });
    }
  }

  async uploadFile(file: File) {
    try {
      this.requestStatus = RequestStatus.Loading;

      const fileUrl = await this.rootStore.fileUploader.prepareFile(file);
      await this.rootStore.fileUploader.uploadFile();

      runInAction(() => {
        this.updateData({ filePath: fileUrl });

        this.requestStatus = RequestStatus.Success;
        this.rootStore.notificationStore.addNotification(
          'File uploaded successfully',
          {
            type: NotificationType.success,
          }
        );
      });
    } catch (err) {
      runInAction(() => {
        console.error(err);
        this.requestStatus = RequestStatus.Error;
        this.rootStore.notificationStore.addNotification(
          'Something went wrong while uploading the file',
          {
            type: NotificationType.error,
          }
        );
      });
    }
  }

  async clearFile() {
    this.requestStatus = RequestStatus.Idle;
    this.rootStore.fileUploader.clear();
    this.filePath = '';
  }

  get canSubmit() {
    return isContentEmpty(this.content) || this.filePath !== '';
  }

  get isDraftSubmitted() {
    return this.status === 'submitted';
  }
}
