import {
  DEFAULT_CRITERION,
  DEFAULT_RANK,
  IAssignmentCriterion,
  IAssigmentRank,
} from './domain';
import { action, computed, makeObservable, observable } from 'mobx';
import { ICriteriaRank, ICriteriaStore, ICriteriaTab } from './types';

export class CriteriaRank implements ICriteriaRank {
  isChanged: boolean;
  id: string;
  name: string;
  description: string;
  gradeWeight: number;

  constructor(initialConfig: IAssigmentRank) {
    this.id = initialConfig.id || crypto.randomUUID();
    this.isChanged = false;
    this.name = initialConfig.name;
    this.description = initialConfig.description;
    this.gradeWeight = initialConfig.gradeWeight;

    makeObservable(this, {
      isChanged: observable,
      name: observable,
      description: observable,
      gradeWeight: observable,

      updateData: action.bound,
      toJS: action.bound,
    });
  }

  updateData(changedFields: Partial<IAssigmentRank>) {
    if (changedFields.name !== undefined) {
      this.name = changedFields.name;
    }

    if (changedFields.description !== undefined) {
      this.description = changedFields.description;
    }

    if (changedFields.gradeWeight !== undefined) {
      this.gradeWeight = changedFields.gradeWeight;
    }

    this.isChanged = true;
  }

  toJS(omitId?: boolean) {
    return {
      id: omitId ? '' : this.id,
      name: this.name,
      description: this.description,
      gradeWeight: this.gradeWeight,
    };
  }
}

export class CriteriaTab implements ICriteriaTab {
  isChanged: boolean;
  id: string;
  name: string;
  gradeWeight: number;
  ranks: CriteriaRank[];

  constructor(initialConfig: IAssignmentCriterion) {
    this.isChanged = false;
    this.id = initialConfig.id || crypto.randomUUID();
    this.name = initialConfig.name;
    this.gradeWeight = initialConfig.gradeWeight;
    this.ranks =
      initialConfig.ranks?.map((data) => new CriteriaRank(data)) || [];
    this.sortRanksByGradesWeight();

    makeObservable(this, {
      isChanged: observable,
      name: observable,
      gradeWeight: observable,
      ranks: observable,

      updateData: action.bound,
      addRank: action.bound,
      removeRank: action.bound,
      sortRanksByGradesWeight: action.bound,
      toJS: action.bound,
    });
  }

  updateData(changedFields: Partial<IAssignmentCriterion>) {
    if (changedFields.name !== undefined) {
      this.name = changedFields.name;
    }

    if (changedFields.gradeWeight !== undefined) {
      this.gradeWeight = changedFields.gradeWeight;
    }

    if (changedFields.ranks !== undefined) {
      this.ranks = changedFields.ranks?.map((data) => new CriteriaRank(data));
    }

    this.isChanged = true;
  }

  addRank() {
    this.ranks.push(new CriteriaRank(DEFAULT_RANK));

    this.isChanged = true;
    this.sortRanksByGradesWeight();
  }

  removeRank(id: string) {
    const rankIndex = this.ranks.findIndex((r) => r.id === id);
    if (rankIndex === -1) return;

    this.ranks.splice(rankIndex, 1);

    this.isChanged = true;
  }

  sortRanksByGradesWeight() {
    this.ranks = this.ranks.sort((a, b) => b.gradeWeight - a.gradeWeight);
  }

  toJS(omitId?: boolean) {
    return {
      id: omitId ? '' : this.id,
      name: this.name,
      gradeWeight: this.gradeWeight,
      ranks: this.ranks.map((r) => r.toJS(omitId)),
    };
  }
}

export class CriteriaStore implements ICriteriaStore {
  isChanged: boolean;
  criteria: CriteriaTab[];

  constructor(initialConfig: IAssignmentCriterion[]) {
    this.isChanged = false;

    this.criteria = initialConfig.map((data) => new CriteriaTab(data));

    makeObservable(this, {
      isChanged: observable,
      criteria: observable,
      isDataChanged: computed,
      allGradesWeight: computed,
      maxScore: computed,

      addCriterion: action.bound,
      removeCriteria: action.bound,
      setCriteria: action.bound,
      reinitializeCriteria: action.bound,
      resetIsChangedFlag: action.bound,
      toCriteriaData: action.bound,
    });
  }

  addCriterion(data?: IAssignmentCriterion) {
    this.criteria.push(new CriteriaTab(data || DEFAULT_CRITERION));

    this.isChanged = true;
  }

  removeCriteria(id: string) {
    const tabIndex = this.criteria.findIndex((c) => c.id === id);
    if (tabIndex === -1) return;

    this.criteria.splice(tabIndex, 1);

    this.isChanged = true;
  }

  setCriteria(criteria: ICriteriaTab[]) {
    this.criteria = criteria;

    this.isChanged = true;
  }

  reinitializeCriteria(criteriaData: IAssignmentCriterion[]) {
    this.criteria = criteriaData.map((data) => new CriteriaTab(data));
  }

  get isDataChanged() {
    return (
      this.isChanged ||
      this.criteria.some((c) => c.isChanged || c.ranks.some((r) => r.isChanged))
    );
  }

  get allGradesWeight() {
    return this.criteria.reduce((p, c) => p + c.gradeWeight, 0);
  }

  get maxScore() {
    let totalScore = 0;

    this.criteria.forEach(({ gradeWeight, ranks }) => {
      totalScore += gradeWeight * Math.max(...ranks.map((r) => r.gradeWeight));
    });

    return totalScore;
  }

  resetIsChangedFlag() {
    this.isChanged = false;
    this.criteria.forEach((c) => {
      c.isChanged = false;
      c.ranks.forEach((r) => {
        r.isChanged = false;
      });
    });
  }

  toCriteriaData() {
    return this.criteria.map((c) => c.toJS());
  }
}
