import { action, makeObservable, observable, reaction } from 'mobx';

import { ICapiModel } from '../capi';
import { IAppStore, IRootStore } from './types';
import { CapiBoundStore, ICAPI, SimMode } from 'asu-sim-toolkit';

export class AppStore extends CapiBoundStore<ICapiModel> implements IAppStore {
  private readonly rootStore: IRootStore;

  cohortId = '';
  instructorId = '';
  userId = '';
  attemptId = '';

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);

    this.rootStore = rootStore;

    makeObservable(this, {
      cohortId: observable,
      attemptId: observable,
      instructorId: observable,
      userId: observable,

      initCohortId: action,
      setRoleId: action,
    });

    this.synchronizeToCapi('cohortId', 'Sim.Configuration.CohortId');
    this.onCapi('Sim.Configuration.CohortId', (cohortId) => {
      if (cohortId) {
        console.log(`Received cohort ID from CAPI - ${cohortId}`);
        this.cohortId = cohortId;
      }
    });

    this.bindToCapi('instructorId', 'Sim.Instructor.Id');

    console.log('Setting up userId synchronization');

    this.onCapi('Sim.User.Id', (userId) => {
      if (userId && userId !== '{session.userId}') {
        console.log(`Received user ID from CAPI - ${userId}`);
        this.userId = userId;
      }
    });

    this.synchronizeToCapi('userId', 'Sim.User.Id');

    reaction(
      () => this.rootStore.simContextStore.userId,
      (userId) => {
        if (userId) {
          console.log(`Received user ID from sim context - ${userId}`);
          // NOTE: Apparently, the userId coming from sim context is a number.
          // TODO: Update AsuSimToolkit to reflect this.
          this.userId = String(userId);
        }
      },
      { fireImmediately: true }
    );

    this.synchronizeToCapi('attemptId', 'Sim.AttemptId');
    this.onCapi('Sim.AttemptId', (attemptId) => {
      if (attemptId) {
        console.log(`Received attempt ID from CAPI - ${attemptId}`);
      } else {
        console.log('Generating attempt ID');
        this.attemptId = crypto.randomUUID();
      }
    });

    reaction(
      () => this.rootStore.simContextStore.mode,
      (mode) => {
        if (mode === 'VIEWER') {
          if (!this.cohortId) {
            this.initCohortId();
          }
          if (!this.attemptId) {
            console.log('Generating attempt ID');
            this.attemptId = crypto.randomUUID();
          }
        }
        this.setRoleId(mode);
      },
      { fireImmediately: true }
    );
  }

  initCohortId() {
    console.log('Initialising cohort ID');
    const config = this.getCapiConfig();
    if (!config) return;

    const { sectionSlug, questionId } = config;
    this.cohortId = `${sectionSlug}_${questionId}`;
  }

  setRoleId(mode: SimMode | undefined) {
    if (mode === 'AUTHOR' && !this.instructorId) {
      this.instructorId = crypto.randomUUID();
    }
  }
}
