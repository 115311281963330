import React, { FC, useState } from 'react';
import { IAssigmentRank, IInstructorReview } from '../../stores/domain';
import { AttachedFile } from '../../components/Displayer/AttachedFile';
import { Draft } from '../../components/Displayer/Draft';
import { ReviewCriteria } from '../../components/ReviewCriteria/ReviewCriteria';
import { ReviewTabButton } from '../../components/TabButton/ReviewTabButton';
import { toPercentage } from '../../utils/to-percentage';
import { useStores } from '../../providers/store/use-stores';
import { observer } from 'mobx-react-lite';

interface IInstructorReviewPageProps {
  review: IInstructorReview;
}

export const InstructorReviewPage: FC<IInstructorReviewPageProps> = observer(
  ({ review }) => {
    const {
      assignmentConfigStore: {
        savedConfig: { criteria },
        criteriaManager: { maxScore },
      },
      gradingStore: { updateRank, totalScore },
    } = useStores();
    const { ranks, content, filePath } = review;

    const [criterionIndex, setCriterionIndex] = useState(0);
    const activeCriterion = criteria[criterionIndex] || criteria[0];

    const changeTab = (id: string) => {
      const tabIndex = criteria.findIndex((t) => t.id === id);

      if (tabIndex > -1) {
        setCriterionIndex(tabIndex);
      }
    };

    const highlightVerifiedTab = (assignmentRanks: IAssigmentRank[]) => {
      return ranks.some((r) =>
        assignmentRanks.some((ar) => ar.id === r.rankId)
      );
    };

    return (
      <>
        <div className="p-6 flex flex-col">
          <div className="mx-2 mb-2 flex justify-between items-center">
            <h3 className="font-semibold text-xl mr-4">Instructor review</h3>
            <span className="text-orange font-semibold text-lg">
              {`Total score: ${totalScore} / ${maxScore} (${toPercentage(
                totalScore,
                maxScore
              )}%)`}
            </span>
          </div>
          <div className="w-full flex flex-col">
            <div className="flex items-center justify-between gap-sm w-full">
              <div className="min-h-[48px] flex flex-1 items-end h-auto overflow-x-auto overflow-y-clip -mb-[1px] z-0">
                {criteria.map(({ name, id, ranks }, index) => (
                  <ReviewTabButton
                    key={id}
                    id={id}
                    isActive={index === criterionIndex}
                    isVerified={highlightVerifiedTab(ranks)}
                    onClick={() => changeTab(id)}
                  >
                    {name}
                  </ReviewTabButton>
                ))}
              </div>
            </div>
            <div className="p-2 rounded-tr-md rounded-b-md border border-gray-500">
              <ReviewCriteria
                criterion={activeCriterion}
                ranks={ranks}
                onRankSelected={updateRank}
              />
            </div>
          </div>
        </div>
        <div className="px-6 w-full h-full">
          <Draft content={content} />
          <AttachedFile filePath={filePath} />
        </div>
      </>
    );
  }
);
