import React, { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

export interface IInstructorViewSectionProps extends PropsWithChildren {
  title?: ReactNode;
  additionalText?: string;
  containerClassName?: string;
  contentClassName?: string;
}

export const InstructorViewSection: FC<IInstructorViewSectionProps> = ({
  title,
  additionalText,
  children,
  containerClassName,
  contentClassName,
}) => (
  <div className={classNames('px-6 py-3', containerClassName)}>
    <div className="flex items-center">
      {title && <h3 className="font-semibold text-lg">{title}</h3>}
      {additionalText && (
        <span className="ml-2 font-semibold text-sm text-orange">
          {additionalText}
        </span>
      )}
    </div>
    <div className={classNames('p-3 flex flex-col gap-3', contentClassName)}>
      {children}
    </div>
  </div>
);
