import React, { FC, useState } from 'react';

import { IAssigmentRank } from '../../stores/domain';
import { ReviewCriteria } from '../../components/ReviewCriteria/ReviewCriteria';
import { ReviewTabButton } from '../../components/TabButton/ReviewTabButton';
import { IReview } from '../../stores/domain';
import { toPercentage } from '../../utils/to-percentage';
import { Review } from '../../components/Displayer/Review';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';

interface IOverviewPage {
  review: IReview;
  content: string;
  filePath?: string;
  title?: string;
}

export const OverviewPage: FC<IOverviewPage> = observer(
  ({ review, content, filePath, title }) => {
    const { score, ranks } = review;
    const {
      assignmentConfigStore: {
        savedConfig: { criteria },
        criteriaManager: { maxScore },
      },
    } = useStores();
    const [criterionIndex, setCriterionIndex] = useState(0);
    const [noteIndex, setNoteIndex] = useState(0);
    const activeCriterion = criteria[criterionIndex] || criteria[0];
    const criteriaNotes = review.notes.filter(
      (n) => n.criteriaId === activeCriterion.id && n.length !== 0
    );
    criteriaNotes.sort((noteA, noteB) => {
      const { startIndex: startIndexA, length: lengthA } = noteA;
      const { startIndex: startIndexB, length: lengthB } = noteB;

      return startIndexA - startIndexB || lengthA - lengthB;
    });
    const generalNote = review.notes.find(
      (n) => n.criteriaId === activeCriterion.id && n.length === 0
    );

    const changeTab = (id: string) => {
      const tabIndex = criteria.findIndex((t) => t.id === id);

      if (tabIndex > -1) {
        setCriterionIndex(tabIndex);
      }
      setNoteIndex(0);
    };

    const highlightVerifiedTab = (assignmentRanks: IAssigmentRank[]) => {
      return ranks.some((r) =>
        assignmentRanks.some((ar) => ar.id === r.rankId)
      );
    };

    return (
      <div className="px-6 pt-6 flex flex-col">
        <div className="mx-2 mb-2 flex justify-between items-center">
          <h3 className="font-semibold text-xl mr-4">{title}</h3>
          <span className="text-orange font-semibold text-lg">
            {`Total score: ${score} / ${maxScore} (${toPercentage(
              score,
              maxScore
            )}%)`}
          </span>
        </div>
        <div className="w-full flex flex-col mb-2">
          <div className="flex items-center justify-between gap-sm w-full">
            <div className="min-h-[48px] flex flex-1 items-end h-auto overflow-x-auto overflow-y-clip -mb-[1px] z-0">
              {criteria.map(({ name, id, ranks }, index) => (
                <ReviewTabButton
                  key={id}
                  id={id}
                  isActive={index === criterionIndex}
                  isVerified={highlightVerifiedTab(ranks)}
                  onClick={() => changeTab(id)}
                >
                  {name}
                </ReviewTabButton>
              ))}
            </div>
          </div>
          <div className="p-2 rounded-b-md rounded-tr-md border border-gray-500">
            <ReviewCriteria criterion={activeCriterion} ranks={ranks} />
          </div>
        </div>
        <Review
          isViewOnly
          content={content}
          filePath={filePath}
          noteIndex={noteIndex}
          criteriaNotes={criteriaNotes}
          generalNote={generalNote}
          activeCriterion={activeCriterion}
          onChangeNoteIndex={(index: number) => setNoteIndex(index)}
        />
      </div>
    );
  }
);
