import { IReview } from '../stores/domain';

import { IReviewDataSource } from './types';
import { IGetReviewApi, IGetReviewsApi, IPutReviewApi } from './types.rest';
import { mapFromReview, mapToReview } from './mappers.rest';

export class ReviewDataSource implements IReviewDataSource {
  constructor(private readonly apiRoot: string) {}

  async getReviews({
    assignmentId,
    draftId,
    userId,
    score,
    status,
  }: {
    assignmentId?: IGetReviewsApi['queryParams']['assignmentId'];
    draftId?: IGetReviewsApi['queryParams']['draftId'];
    userId?: IGetReviewsApi['queryParams']['reviewerId'];
    score?: IGetReviewsApi['queryParams']['score'];
    status?: IGetReviewsApi['queryParams']['status'];
  }): Promise<IReview[]> {
    const url = new URL(`${this.apiRoot}/reviews`);

    if (assignmentId !== undefined) {
      url.searchParams.append('assignmentId', assignmentId);
    }
    if (draftId !== undefined) {
      url.searchParams.append('draftId', draftId);
    }
    if (userId !== undefined) {
      url.searchParams.append('reviewerId', userId);
    }
    if (score !== undefined) {
      url.searchParams.append('score', String(score));
    }
    if (status !== undefined) {
      url.searchParams.append('status', String(status));
    }

    const res = await fetch(url.toString());

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IGetReviewsApi['response'];

    return data.map((item) => mapToReview(item));
  }

  async getReview(
    reviewId: IGetReviewApi['pathParams']['reviewId']
  ): Promise<IReview> {
    const res = await fetch(`${this.apiRoot}/reviews/${reviewId}`);

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IGetReviewApi['response'];

    return mapToReview(data);
  }

  async updateReview(
    review: IReview,
    reviewId: IPutReviewApi['pathParams']['reviewId']
  ): Promise<IReview> {
    const payload: IPutReviewApi['payload'] = mapFromReview(review);

    const res = await fetch(`${this.apiRoot}/reviews/${reviewId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    // TODO: Validate this with Zod or some other schema checker.
    const { data } = (await res.json()) as IPutReviewApi['response'];

    return mapToReview(data);
  }
}
