import { Mapper } from 'asu-sim-toolkit';
import {
  IAssigmentRank,
  IAssignmentConfig,
  IAssignmentCriterion,
  IDraft,
  INote,
  IRankScore,
  IReview,
} from '../stores/domain';
import {
  IApiAssignmentConfig,
  IApiAssignmentCriterion,
  IApiAssignmentRank,
  IApiDraft,
  IApiNote,
  IApiReview,
  IApiReviewRank,
  IPutReviewApi,
} from './types.rest';

export const mapToAssignmentRank: Mapper<
  IApiAssignmentRank,
  IAssigmentRank
> = ({ id, title, value, description }) => ({
  id,
  description,
  gradeWeight: value,
  name: title,
});

export const mapFromAssignmentRank: Mapper<
  IAssigmentRank,
  IApiAssignmentRank
> = ({ id, description, gradeWeight, name }) => ({
  id,
  description,
  title: name,
  value: gradeWeight,
});

export const mapToAssignmentCriterion: Mapper<
  IApiAssignmentCriterion,
  IAssignmentCriterion
> = ({ id, gradeWeight, name, ranks }) => ({
  id,
  gradeWeight,
  name,
  ranks: ranks.map(mapToAssignmentRank),
});

export const mapFromAssignmentCriterion: Mapper<
  IAssignmentCriterion,
  IApiAssignmentCriterion
> = ({ id, gradeWeight, name, ranks }) => ({
  id,
  gradeWeight,
  name,
  ranks: ranks.map(mapFromAssignmentRank),
});

export const mapToAssignmentConfig: Mapper<
  IApiAssignmentConfig,
  IAssignmentConfig
> = ({
  id,
  criteria,
  minNumSubmissions,
  numReviewsPerStudent,
  summary,
  title,
}) => ({
  id,
  criteria: criteria.map(mapToAssignmentCriterion),
  minNumSubmissions,
  numReviewsPerStudent,
  summary,
  title,
});

export const mapFromAssignmentConfig: Mapper<
  IAssignmentConfig,
  Omit<
    IApiAssignmentConfig,
    'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'
  >
> = ({
  criteria,
  minNumSubmissions,
  numReviewsPerStudent,
  summary,
  title,
}) => ({
  title,
  summary: summary || '',
  criteria: criteria.map(mapFromAssignmentCriterion),
  minNumSubmissions,
  numReviewsPerStudent,
});

export const mapToDraft: Mapper<IApiDraft, IDraft> = ({
  id,
  assignmentId,
  cohortId,
  studentId,
  status,
  filePath,
  content,
  submittedReviews,
  allReviews,
}) => ({
  id,
  assignmentId,
  cohortId,
  userId: studentId,
  status,
  filePath,
  content: content || '',
  numberOfSubmittedReviews: submittedReviews || 0,
  numberOfAllReviews: allReviews || 0,
});

export const mapToRank: Mapper<IApiReviewRank, IRankScore> = ({
  criteriaId,
  rankId,
  score,
}) => ({
  rankId,
  criteriaId,
  score,
});

export const mapFromRank: Mapper<IRankScore, IApiReviewRank> = ({
  rankId,
  criteriaId,
  score,
}) => ({
  criteriaId,
  rankId,
  score,
});

export const mapToNote: Mapper<IApiNote, INote> = ({
  id,
  criteriaId,
  content,
  startIndex,
  length,
}) => ({
  id,
  criteriaId,
  content,
  startIndex,
  length,
});

export const mapFromNote: Mapper<INote, IApiNote> = ({
  id,
  criteriaId,
  content,
  startIndex,
  length,
}) => ({
  id,
  criteriaId,
  content,
  startIndex,
  length,
});

export const mapToReview: Mapper<IApiReview, IReview> = ({
  id,
  reviewerId,
  assignmentId,
  draftId,
  ranks,
  score,
  notes,
  status,
}) => ({
  id,
  userId: reviewerId,
  assignmentId,
  draftId,
  ranks: ranks?.map(mapToRank) || [],
  score,
  notes: notes?.map(mapToNote) || [],
  status,
});

export const mapFromReview: Mapper<IReview, IPutReviewApi['payload']> = ({
  userId,
  ranks,
  notes,
  status,
  score,
}) => ({
  data: {
    ranks: ranks.map(mapFromRank),
    notes: notes.map(mapFromNote),
    status,
    score,
  },
  userId,
});
