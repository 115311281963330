import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';
import { Header } from '../../components/Header/Header';
import { Button } from '../../components/Button/Button';
import { IAssigmentRank, RequestStatus } from '../../stores/domain';
import { ReviewCriteria } from '../../components/ReviewCriteria/ReviewCriteria';
import { ISolutionReview } from '../../stores/types';
import { Spinner } from '../../components/Spinner/Spinner';
import { ReviewTabButton } from '../../components/TabButton/ReviewTabButton';
import { getFullscreenAction } from '../../components/Header/actions';
import { toPercentage } from '../../utils/to-percentage';
import { Range } from 'react-quill';
import { Review } from '../../components/Displayer/Review';

interface IReviewViewProps {
  activeReview: ISolutionReview;

  goBack?(): void;
}

export const ReviewView: FC<IReviewViewProps> = observer(
  ({ activeReview, goBack }) => {
    const {
      fullscreenStore,
      assignmentConfigStore: {
        savedConfig: { title, criteria },
        criteriaManager: { maxScore },
      },
    } = useStores();
    const [criterionIndex, setCriterionIndex] = useState(0);
    const [noteIndex, setNoteIndex] = useState(0);
    const [selectedRange, setSelectedRange] = useState<Range>(null);
    const activeCriterion = criteria[criterionIndex];
    const criteriaNotes = activeReview.notes.filter(
      (n) => n.criteriaId === activeCriterion.id && n.length !== 0
    );
    const generalNote = activeReview.notes.find(
      (n) => n.criteriaId === activeCriterion.id && n.length === 0
    );

    const {
      canSubmit,
      isChanged,
      ranks,
      save,
      submit,
      updateNote,
      addNote,
      removeNote,
      updateRank,
      requestStatus,
      content,
      filePath,
      totalScore,
    } = activeReview;

    const changeTab = (id: string) => {
      const tabIndex = criteria.findIndex((t) => t.id === id);

      if (tabIndex > -1) {
        setCriterionIndex(tabIndex);
        setNoteIndex(0);
      }
    };

    const highlightVerifiedTab = (assignmentRanks: IAssigmentRank[]) => {
      return ranks.some((r) =>
        assignmentRanks.some((ar) => ar.id === r.rankId)
      );
    };

    const handleSelection = (selection: Range) => {
      if (selection?.index === 0 && selection?.length === 0) {
        return;
      }
      setSelectedRange(selection);
    };

    return (
      <div className="w-full bg-white">
        <Header
          title={title}
          action={getFullscreenAction(fullscreenStore)}
          goBack={goBack}
        >
          <div className="flex gap-3">
            <Button onClick={() => save()} disabled={!isChanged}>
              Save
            </Button>
            <Button
              onClick={async () => {
                await submit();
                {
                  goBack && goBack();
                }
              }}
              disabled={!canSubmit}
            >
              Submit
            </Button>
          </div>
        </Header>
        <div className="p-6">
          {requestStatus === RequestStatus.Loading ? (
            <Spinner scale={2} />
          ) : (
            <>
              <div className="mx-2 mb-2 flex justify-between align-center">
                <div className="p-2 rounded-md bg-gray-50 text-sm text-warning">
                  Click on a rating to select it
                </div>
                <span className="text-orange font-semibold">
                  {`Total score: ${totalScore} / ${maxScore} (${toPercentage(
                    totalScore,
                    maxScore
                  )}%)`}
                </span>
              </div>
              <div className="w-full flex flex-col mb-2">
                <div className="flex items-center justify-between gap-sm w-full">
                  <div className="min-h-[48px] flex flex-1 items-end h-auto overflow-x-auto overflow-y-clip -mb-[1px] z-0">
                    {criteria.map(({ name, id, ranks }, index) => (
                      <ReviewTabButton
                        key={id}
                        id={id}
                        isActive={index === criterionIndex}
                        isVerified={highlightVerifiedTab(ranks)}
                        onClick={() => changeTab(id)}
                      >
                        {name}
                      </ReviewTabButton>
                    ))}
                  </div>
                </div>
                <div className="p-2 rounded-b-md rounded-tr-md border border-gray-500">
                  <ReviewCriteria
                    criterion={activeCriterion}
                    ranks={ranks}
                    onRankSelected={updateRank}
                  />
                </div>
              </div>
              <Review
                content={content}
                filePath={filePath}
                noteIndex={noteIndex}
                criteriaNotes={criteriaNotes}
                generalNote={generalNote}
                activeCriterion={activeCriterion}
                selectedRange={selectedRange}
                onSelection={handleSelection}
                onChangeNoteIndex={(index: number) => setNoteIndex(index)}
                onAddNote={addNote}
                onUpdateNote={updateNote}
                onRemoveNote={removeNote}
              />
            </>
          )}
        </div>
      </div>
    );
  }
);
