import React, { ChangeEvent, FC, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import { EditorToolbar, formats } from './EditorToolbar';
import classNames from 'classnames';
import { RequestStatus } from '../../stores/domain';
import { isFilePDF } from '../../utils/is-file-pdf';
import { buttonSecondaryClasses, Button } from '../Button/Button';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton/IconButton';
import { Spinner } from '../Spinner/Spinner';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';

interface IEditorProps {
  value: string;
  filePath?: string;
  requestStatus: RequestStatus;
  uploadFile: (file: File) => Promise<void>;
  clearFile: () => void;
  placeholder?: string;
  className?: string;

  onChange(value: string): void;
}

export const Editor: FC<IEditorProps> = observer(
  ({
    placeholder,
    value,
    onChange,
    filePath,
    requestStatus,
    uploadFile,
    clearFile,
    className,
  }) => {
    const { fileUploader } = useStores();
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleFileInputClick = () => {
      if (hiddenFileInput?.current) {
        hiddenFileInput.current.click();
      }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      const fileUploaded = event?.target?.files?.[0];

      if (fileUploaded !== undefined) {
        uploadFile(fileUploaded);
      }
    };

    function imageHandler() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const quill = this.quill;

      async function saveToServer(file: File) {
        if (!quill) return;
        quill.focus();
        const { index } = quill.getSelection(true);
        const text = `[uploading ${file.name}]`;
        quill.insertText(index, text);
        const attachedFileUrl = await fileUploader.prepareFile(file);
        await fileUploader.uploadFile();
        fileUploader.clear();
        quill.deleteText(index, text.length);
        quill.insertEmbed(index, 'image', attachedFileUrl);
      }
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
      input.onchange = async () => {
        const file = input.files?.[0];
        if (!file) return;
        if (/^image\//.test(file.type)) {
          await saveToServer(file);
        } else {
          console.warn('You can only upload images.');
        }
      };
    }

    const modules = useMemo(
      () => ({
        toolbar: {
          container: '#toolbar',
          handlers: {
            image: imageHandler,
          },
        },
        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return (
      <div className={classNames('flex flex-col', className)}>
        <EditorToolbar />
        <ReactQuill
          value={value}
          className="h-full"
          onChange={onChange}
          placeholder={placeholder}
          modules={modules}
          formats={formats}
        />
        <div className="text-center flex flex-col items-center justify-center">
          {requestStatus === RequestStatus.Loading ? (
            <div className="text-center flex flex-col gap-1">
              <Spinner />
              <span>Uploading file...</span>
            </div>
          ) : (
            filePath && (
              <div className="w-full h-full text-center">
                <div className="flex items-center justify-center">
                  <a
                    className={classNames(
                      buttonSecondaryClasses,
                      'flex items-center rounded-md font-semibold py-1.5 px-1'
                    )}
                    href={filePath}
                    title={fileUploader.fileName}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon id="download" className="mr-1 text-lg" />
                    <span
                      className="truncate text-right max-w-[150px]"
                      style={{ direction: 'rtl' }}
                    >
                      {fileUploader.fileName || filePath}
                    </span>
                  </a>
                  <IconButton
                    iconId="close"
                    onClick={clearFile}
                    className="text-orange"
                  />
                </div>
                <embed
                  src={filePath}
                  width="100%"
                  height="800px"
                  className="border-t border-b border-gray"
                  style={{ height: isFilePDF(filePath) ? 800 : 'auto' }}
                />
              </div>
            )
          )}
          {!filePath && requestStatus !== RequestStatus.Loading && (
            <>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                hidden
              />
              <Button
                className={classNames(buttonSecondaryClasses, 'font-semibold')}
                onClick={handleFileInputClick}
              >
                <Icon id="attach" className="mr-1 text-lg" /> Attach file
              </Button>
            </>
          )}
        </div>
      </div>
    );
  }
);
