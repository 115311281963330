import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  IconButton,
  IconButtonPrimaryClasses,
} from '../../components/IconButton/IconButton';
import { Editor } from '../../components/Editor/Editor';
import { useStores } from '../../providers/store/use-stores';
import { Header } from '../../components/Header/Header';
import { Button } from '../../components/Button/Button';
import { getFullscreenAction } from '../../components/Header/actions';
import { Icon } from '../../components/Icon';
import { Tooltip } from 'react-tooltip';

interface IDraftEditViewProps {
  goBack(): void;
}

export const DraftEditView: FC<IDraftEditViewProps> = observer(({ goBack }) => {
  const {
    fullscreenStore,
    assignmentConfigStore: {
      savedConfig: { title, summary, criteria },
    },
    solutionDraftStore: {
      canSubmit,
      content,
      isChanged,
      save,
      submit,
      updateData,
      filePath,
      requestStatus,
      uploadFile,
      clearFile,
    },
  } = useStores();
  const [criteriaIndex, setCriteriaIndex] = useState(0);

  const criteriaTitle = `${criteriaIndex + 1} of ${criteria.length}: ${
    criteria[criteriaIndex].name
  }`;

  return (
    <div className="w-full h-full flex flex-col bg-white">
      <Header
        title={title}
        action={getFullscreenAction(fullscreenStore)}
        goBack={goBack}
      >
        <div className="flex gap-3">
          <Button onClick={() => save()} disabled={!isChanged}>
            Save
          </Button>
          <Button
            onClick={async () => {
              await submit();
              goBack();
            }}
            disabled={!canSubmit}
          >
            Submit
          </Button>
        </div>
      </Header>
      <p className="p-6">{summary}</p>
      <div className="h-full p-6">
        <div className="min-h-full flex border border-gray rounded-md">
          <Editor
            value={content}
            onChange={(v) => {
              updateData({ content: v });
            }}
            filePath={filePath}
            requestStatus={requestStatus}
            uploadFile={uploadFile}
            clearFile={clearFile}
            placeholder="This is where you will enter your response to the assignment prompt"
            className="w-2/3 pb-6"
          />
          <div className="w-1/3 bg-gray-200 relative">
            <div className="text-lg font-semibold absolute top-[-35px] left-0 bg-gray-400 rounded-t-md flex items-center justify-center px-4 pt-2">
              Rubric
              <div
                className="ml-1"
                data-tooltip-id="rubric-info"
                data-tooltip-content="Use this rubric for your draft submission. This&nbsp;rubric will also be used for your peers to review your work and for you to review theirs. Make sure to check out all criteria pages."
              >
                <Icon id="info" className="text-xl -mt-0.5 text-blue" />
                <Tooltip
                  disableStyleInjection
                  id="rubric-info"
                  place="top"
                  className="py-1.5 px-2 rounded-md text-sm w-[350px] font-normal"
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2 bg-gray-400 p-2 rounded-tr-md">
              <h5 className="text-lg font-semibold">{criteriaTitle}</h5>
              <div className="flex gap-2">
                <IconButton
                  className={IconButtonPrimaryClasses}
                  iconId="chevron-left"
                  id="criteria-go-left"
                  disabled={criteriaIndex === 0}
                  onClick={() => setCriteriaIndex((i) => i - 1)}
                />
                <IconButton
                  className={IconButtonPrimaryClasses}
                  iconId="chevron-right"
                  id="criteria-go-right"
                  disabled={criteriaIndex === criteria.length - 1}
                  onClick={() => setCriteriaIndex((i) => i + 1)}
                />
              </div>
            </div>
            <div className="flex flex-col py-2">
              {criteria[criteriaIndex].ranks.map((g, i) => (
                <div key={`grade-${i}`} className="p-2">
                  <h6 className="font-semibold uppercase">{g.name}:</h6>
                  <p className="text-sm">{g.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
